import React from "react";
import "./style.scss";
const Trem = () => {
  return (
    <>
      <main className="wrap">
        <section className="container">
          <div className="container__heading">
            <h2>Terms &amp; Conditions</h2>
          </div>
          <div
            className="container__content"
            style={{ color: "#000", marginTop: "20" }}
          >
            <p>
              Welcome to GamesBox ! Here is a quick summary of the highlights
              of our Terms of Service:
            </p>{" "}
            <br />
            <span>
              • You own the content that you upload and publish; you also grant
              us and other users of gamesboxcertain rights and license to use
              it. The details of these licenses are described in Section (3)
              below. <br /> <br />
              • You are responsible for the content that you publish. This
              includes ensuring that you have the rights needed for you to post
              the content and that your design does not violate the legal rights
              of another party (for example, defamation) or any applicable laws.{" "}
              <br /> <br />
              • You can repost the content from gamesboxelsewhere, provided
              that that you attribute the design back to gamesboxand respect
              the rights of the original poster, including any “CC Attribution”
              designated for the content. <br /> <br />
              • You are responsible for the information displayed on your
              profile. You can enter your work, educational, personal
              information and information about your social profile. You can
              check your Privacy Settings to maintain what information is
              visible to public. <br /> <br />
              • We do not endorse or verify work and content posted by users.
              Our content and materials are provided to you “as is,” without any
              guarantees. <br /> <br />
              • You are solely responsible for your own use of the GamesBox .
              In case you see any violation of intellectual property rights/CC
              licenses, we may contact the content owner on your behalf to
              settle the issue. You need to right to us for such cases on
              connect@GamesBox .com. <br /> <br />
              • You agree to follow the rules of GamesBox . Your use of
              gamesboxand its services is also subject to our Privacy
              Policy, Acceptable Use Policy, Copyright Policy, and Trademark
              Policy. <br /> <br />
              • We offer tools for you to give feedback and report complaints.
              If you think someone has violated your intellectual property
              rights, other laws, or GamesBox ’s policies, you can initiate a
              report at the contact us portal or by using our in-product
              reporting tool such as “report”. <br /> <br />
            </span>{" "}
            <br />
            <p>
              We are pleased that you want to join gamesboxand encourage you
              to read the full Terms of Service.
            </p>
            <h4>1. User Agreement</h4>
            <h4>1.1 Contract</h4>
            <p>
              You agree that by clicking “Sign up”, Sign up with social
              platforms or similar, registering, accessing or using our services
              (described below), you are agreeing to enter into a legally
              binding Contract with gamesbox(even if you are using our
              Services on behalf of a company). If you do not agree to this
              contract (“Contract” or “User Agreement”), do not click “Sign up”
              (or similar) and do not access or otherwise use any of our
              Services. <br /> <br />
              These terms of service (“Terms”) sets forth the agreement
              (“Agreement”) between you and Design Fist Private Limited,
              (“GamesBox ” “we” or “us”). It governs your use of the products
              and services we offer through our website, applications and
              products (collectively “GamesBox ”) as well as through Creative
              Common license (“CC” Attribution).  Your personal data provided
              to, or collected by or for, our Services is controlled by
              gamesboxand will be governed under the law of NCT, India.{" "}
              <br /> <br />
              Your use of our Services is also subject to our Privacy
              Policy and Cookie Policy. <br /> <br />
              You agree that by clicking “Sign up”, Sign up with social
              platforms or similar, registering, accessing or using our
              services, you are agreeing to enter into a legally binding
              Contract with GamesBox .
            </p>
            <h4>1.2 Users and Visitors</h4>
            <p>
              This Contract applies to gamesbox.in, its branded websites and
              other gamesbox-related sites, apps, communications and other
              services that state that they are offered under this Contract
              (“Services”), including the offsite collection of data for those
              services such as webinars. Registered users of our Services are
              “Users” and unregistered users are “Visitors”. This Contract
              applies to both. <br /> <br />
              When you register and join gamesboxor enroll/subscribe for any
              of our branded websites you become a User. If you have chosen not
              to register for our services and our branded websites, you may
              access certain features as a visitor.
            </p>
            <h4>1.3 Change of Terms</h4>
            <p>
              We can change these Terms at any time including our Privacy Policy
              and our Cookie Policy from time to time. We keep a historical
              record of all changes under Archive. If a change is material,
              we’ll let you know before they take effect. By using gamesboxon
              or after that effective date, you agree to the new Terms. If you
              don’t agree to them, you should delete your account before they
              take effect, otherwise your use of the site and content will be
              subject to the new Terms.
            </p>
            <p>
              By using GamesBox , you agree to these Terms. If you don’t agree
              to any of the Terms, you can’t use GamesBox .
            </p>
            <h4>2. Use of GamesBox </h4>
            <h4>2.1 Who Can Use It</h4>
            <p>
              Use of gamesboxby anyone under 13 years of age is prohibited.
              You represent that you are at least the age of majority in the
              jurisdiction where you live or, if you are not, your parent or
              legal guardian must consent to this Terms of Service and affirm
              that they accept this Agreement on your behalf and bear
              responsibility for your use.
            </p>
            <p>
              At gamesboxUsers are account holders. As a User you agree to:
              (a) try to choose a strong and secure password; (2) keep your
              password secure and confidential; (3) not transfer/sell any part
              of your account if the service for the same has not been provided
              on our website; and (4) follow the law and our list of Dos and
              Don’ts. You are responsible for anything that happens through your
              account unless you close it or report misuse.
            </p>
            <h4>2.2 Registration</h4>
            <p>
              When you set up a profile on the GamesBox , you will be asked to
              provide certain information about yourself. You agree to provide
              us accurate information, including your real name, when you create
              your account on the GamesBox . We will treat information you
              provide as part of registration in accordance with our Privacy
              Policy. You should take care in maintaining the confidentiality of
              your password.
            </p>
            <p>
              In case our system detects that the data submitted at registration
              doesn’t comply with our Terms we may ask you to rectify or verify
              the information.
            </p>
            <h4>2.3 Privacy Policy</h4>
            <p>
              Our Privacy practices are set forth in our Privacy Policy. By use
              of GamesBox , you agree to accept our Privacy Policy, regardless
              of whether you are a user or a visitor.
            </p>
            <h4>2.4 Notices and Service Messages</h4>
            <p>
              You agree that we will provide notices to you in the following
              ways : (1) a notice within the Service, or (2) a message sent to
              the contact information you provided us (e.g., email, mobile
              number, physical address). You agree to keep your contact
              information up to date.
            </p>
            <p>
              Please review your Settings to control and limit the messages you
              receive from us.
            </p>
            <h4>2.5 Sharing of Information</h4>
            <p>
              Our Services allow messaging and sharing of information in many
              ways, such as your profile, your publication. Information and
              content that you share or publish may be seen by other Users or
              Visitors. Where we have made Settings available, we will honor the
              choices you make about who can see content or information{" "}
            </p>
            <p>
              We are not obligated to publish any information or content on our
              Service and can remove it in our sole discretion, with or without
              notice.
            </p>
            <h4>2.6 Competitions and Awards</h4>
            <p>
              When you participate in a hosted competition/award on GamesBox 
              you abide by the rules of the competition. You also agree that you
              are not doing any copyright infringement and retain ownership of
              the copyright and other intellectual property you submit on
              GamesBox .{" "}
            </p>
            <h4>2.7 Acceptable Use Policy</h4>
            <p>
              In your interaction with others on GamesBox , you agree to follow
              the Acceptable Use Policy at all times.
            </p>
            <h4>2.8 Termination</h4>
            <p>
              You may close your account at any time by going to account
              Settings and deleting your account. It may take us up to 30 days
              to completely remove your account. In case you login back in to
              your account in the aforementioned period your account will get
              reactivated again. We may terminate or suspend your GamesBox 
              account if you violate any gamesboxpolicy or for any other
              reason.
            </p>
            <h4>2.9 Changes on GamesBox .</h4>
            <p>
              We are always trying to improve your experience on the GamesBox .
              We may need to add or change features and may do so without notice
              to you. For any material changes/features which results in the
              change of Terms and Privacy, we will notify you in prior.
            </p>
            <h4>2.10 Feedback</h4>
            <p>
              We welcome your feedback and suggestions about how to improve
              GamesBox . Feel free to submit feedback by dropping us a mail
              at connect@GamesBox .com . By submitting feedback, you agree to
              grant us the right, at our discretion, to use, disclose and
              otherwise exploit the feedback, in whole or part, freely and
              without compensation to you.
            </p>
            <h4>2.11 Anti-discrimination</h4>
            <p>
              gamesboxdoes not support and will not tolerate its Service
              being used to discriminate against others, especially when based
              on race, religion, sex, sexual orientation, age, disability,
              ancestry or national origin. You are not permitted to use the
              Service in a manner which would or would likely incite, promote or
              support such discrimination and you must not use the Service to
              incite or promote hostility or violence. If we believe in our sole
              determination that your use of the Service is being used to
              discriminate, especially if based on race, religion, sex, sexual
              orientation, age, disability, ancestry or national origin, we may
              permanently or temporarily terminate or suspend your access to the
              Service without notice and liability for any reason.
            </p>
            <h2>3 Content Rights & Responsibilities</h2>
            <h4>3.1 Definition of Your Content</h4>
            <p>
              gamesboxenables you to add content to share with others. All
              material that you upload, publish or display to others via
              gamesboxwill be referred to collectively as “Your content” You
              acknowledge and agree that, as part of using GamesBox , your
              content may be viewed by the general public including Users and
              visitors and may appear in search engines as well depending on the
              creative content licenses assigned to your content.{" "}
            </p>
            <h4>3.1.1 Definition of Your Work</h4>
            <p>
              At gamesboxyou can showcase your “Work” hereby will be referred
              as your “content”, this enables you to put your intellectual and
              creative property on gamesboxand can be viewed publicly. While
              showcasing your work you will need to assign the CC Attribution
              (learn more about the CC attribution) to your work, this will
              govern the way your content is being displayed on gamesboxand
              outside of it over search engines. CC Attribution will also define
              the rights of reproduction of your content and how it will be used
              over internet.
            </p>
            <p>
              If your work is not suitable for Minors you will need to specify
              the content as “adult content” while uploading. Other users can
              also report about the content and upon verification we may change
              the attribution to your content and notify you for the same. If
              you think that this is not correct you may “appeal” for the same.
            </p>
            <h4>3.2 Ownership</h4>
            <p>
              As between you and GamesBox , you, or your licensor, as
              applicable, retain ownership of the copyright and other
              intellectual property in your content, subject to following
              non-exclusive rights granted to us: A worldwide, transferable and
              sub-licensable right to use, copy, modify, distribute, publish and
              process information and content that you provide through our
              Services, without any further consent, notice and/or compensation
              to you or others. This also means you assume all risks related to
              it, including someone else’s reliance on its accuracy, or claims
              relating to intellectual property or other legal rights.
            </p>
            <h4>3.3 Content Rights & Responsibilities</h4>
            <p>
              As between you and GamesBox , you own the content and information
              that you submit or publish to the Services and you are only
              granting gamesboxand our affiliate the following non-exclusive
              license: A worldwide, transferable and sub-licensable right to
              use, copy, modify, distribute, publish and process information and
              content that you provide through our Services, without any further
              consent, notice and/or compensation to you or others. We may also
              use your content to promote Medium, including its products and
              content. We will not sell your content to third parties without
              your explicit permission. These rights are limited on following
              ways:
            </p>
            <span>
              • You can end this license for specific content by deleting such
              content from the Services or generally by closing your account
              except (a) to the extent you shared it with others as part of the
              Service and they copied, re-shared it or stored it and (b) for the
              reasonable time it takes to remove from backup and other systems.{" "}
              <br /> <br />
              • We will get your consent if we want to give third parties the
              right to publish your posts beyond the Service. However, other
              Users and/or Visitors may access and share your content and
              information, consistent with your choices. In such cases the right
              of CC attribution will always remain with you. <br /> <br />
              • While we may edit and make formatting changes to your content
              (such as translating it, modifying the size, layout or file type
              or removing metadata), we will not modify the meaning of your
              expression. <br /> <br />
              • You are welcome to publish content on gamesboxthat you’ve
              published elsewhere, as long as you have the rights you need to do
              so. By publishing content to GamesBox , you represent that doing
              so doesn’t conflict with any other agreement you’ve made with your
              co-worker, company, friends, or other similar platforms over
              Internet. <br /> <br />
              • By publishing content you didn’t create to GamesBox , you are
              representing that you have the right to do so. For example, you
              are posting a work that has been referenced or taken from a public
              domain, used under license (including a free license, such as
              Creative Commons), or a fair use. <br /> <br />
              You accept full responsibility for avoiding infringement of the
              intellectual property or personal rights of others or violation of
              laws and regulations in connection with your content. You are
              responsible for ensuring that your content does not violate our
              Acceptable Use Policy, Copyright Policy, Trademark Policy, other
              published policy, or any applicable law or regulation. You agree
              to pay all royalties, fees and any other monies owed to any person
              by reason of your content. <br /> <br />
              • Because you own your content and information and we only have
              non-exclusive rights to it, you may choose to make it available to
              others, including under the terms of a CC license. <br /> <br />
              • You also acknowledge and agree that we may preserve your content
              and may also disclose your content and related information if
              required to do so by law or in the good faith belief that such
              preservation or disclosure in reasonably necessary to: (a) comply
              with legal process, applicable laws or government requests; (b)
              comply with legal process, applicable laws or government requests;
              (d) detect, prevent, or otherwise address fraud, security or
              technical issues; or (e) protect the rights, property, or personal
              safety of GamesBox , its Users, or the public. <br /> <br />
              • You understand that we may modify, adapt, or create derivative
              works from your content in order to transmit, display or
              distribute it over computer networks, devices, service providers,
              and in various media. We also may remove or refuse to publish your
              content, in whole or part, at any time. <br /> <br />
              • You further give us the permission and authority to act as your
              non-exclusive agent to take enforcement action against any
              unauthorized use by third-parties of any of your content outside
              gamesboxor in violation of our Terms of Service. <br /> <br />
            </span>
            <h4>3.4 Our Content and Service Availability</h4>
            <h4>3.4.1 Definition of Our Content and Materials.</h4>
            <p>
              All intellectual property in or related to GamesBox 
              (specifically including, but not limited to our software, our
              associated apps, website, logos but excluding your content) is the
              property of GamesBox , or its subsidiaries and affiliates, or
              content posted by other users on gamesboxlicensed to us
              (collectively “Our Content and Service availability”). We reserve
              all rights to change GamesBox ’s look and feel. You may not copy
              or adapt any portion of our code or visual design elements
              (including logos) without express written permission from
              gamesboxunless otherwise permitted by law.
            </p>
            <h4>3.4.2 Featured Content</h4>
            <p>
              Our editorial team may push some content on top without any
              monetary promotion to maintain the quality of content that is
              being displayed on GamesBox . Our team curate contents daily but
              it is also possible that we may take few days to push new curated
              contents on top. We do charge to promote or feature their content.
              In Case you want to get featured you can contact us by dropping a
              mail to connect@GamesBox .com
            </p>
            <h4>3.4.3 Data</h4>
            <p>
              All data that gamesboxcollects (“data”) about use of our
              website and associated apps by you or others is the property of
              GamesBox , its subsidiaries, and affiliates. For clarity, data
              does not include your content and is separate from our content and
              materials.
            </p>
            <h4>3.4.4 Service Availability</h4>
            <p>
              We may change or discontinue any of our services. We don’t promise
              to store or keep showing any information and content that you‘ve
              posted.
            </p>
            <p>
              At gamesboxwe store some of your information including images,
              media and text, however we are not providing any active storage
              service. You agree that we have no obligation to store, maintain
              or provide you a copy of any content or information that you or
              others provide, except to the extent required by applicable law
              and as noted in our Privacy Policy.
            </p>
            <h4>3.4.5 Other Content, Sites and Apps</h4>
            <p>
              By using the services, you may encounter content or information
              that might be inaccurate, incomplete, delayed, misleading,
              illegal, offensive or otherwise harmful. gamesboxgenerally does
              not review content provided by our Users or others. You agree that
              we are not responsible for others’ (including other Users’)
              content or information. We cannot always prevent the misuse of our
              services, and you agree that we are not responsible for any such
              misuse. You also acknowledge the risk that you, your work, team or
              your organization may be mistakenly associated with content about
              others when we let followers know you or your organization were
              mentioned in the news. You may opt out of this feature.
            </p>
            <p>
              To enable a functioning community, we have Rules. To ensure
              usernames are distributed and used fairly, we have a Username
              Policy. Under our Trademark Policy, we’ll investigate any use of
              another’s Trademark and respond appropriately. You are responsible
              for deciding if you want to access of use third party apps or
              sites that link from our Services. If you allow a third-party app
              or site to authenticate you or connect with your GamesBox 
              account, that app or site can access information on GamesBox 
              related to you and your connections. Third party apps and sites
              have their own legal Terms and Privacy Policies, and you may be
              giving other permission to use your information in ways we would
              not. Except to the limited extent it may be required by applicable
              law, gamesboxis not responsible for these other sites and apps
              – use these at your own risk. Please see out Privacy Policy.
            </p>
            <h4>3.4.6 Our License to You</h4>
            <span>
              • We grant you a limited, non-exclusive license to use and access
              Our Content, Services and data as made available to you on
              gamesboxin connection with your use of our services, subject to
              the terms and conditions of this Agreement. <br /> <br />•
              gamesboxgives you a worldwide, royalty free, revocable,
              non-assignable and non-exclusive license to re-post Our content
              and Materials anywhere on the web provided that : (a) the user who
              created the content has not explicitly marked the content as not
              for reproduction under the CC license. (b) you do not modify the
              content unless mentioned otherwise; (c) you attribute GamesBox 
              by name in readable text and with a human and machine-followable
              link (an HTML <></> anchor tag) linking back to the page
              displaying the original source of the content on
              https://gamesbox.in on every page that contains our content and
              materials; (d) upon request, either by gamesboxor a user, you
              remove the user’s name from content where user has objection. e.g.
              (discussions, team members) (e) upon request either by GamesBox 
              or by a user who contributed to the content, you make a reasonable
              effort to update a particular piece of content to the latest
              version on GamesBox ; and (f) upon request, either by GamesBox 
              or by a user who contributed to the content that has been deleted
              or marked as not for reproduction under CC attribution. (g) you
              don’t republish more than a small portion of our content. In
              exercising these rights, you may not implicitly or explicitly
              assert any connection with, sponsorship or endorsement by
              gamesboxor any gamesboxuser, without separate, express prior
              written permission from us. <br /> <br />
              • You are not entitled to run any active promotion on GamesBox 
              or use its services to promote content that does not belong to
              you. You will also not use gamesboxto promote your company or
              its services except you and your company’s intellectual property.
              We may push your content down on our feed if we find it to be an
              active promotion and acts as a spamming tool to misuse our
              services. <br /> <br />
              • We may terminate our license to you at any time for any reason.
              We have the right but not the obligation to refuse to distribute
              any content on gamesboxor to remove content. Except the rights
              and license granted in these Terms, we reserve all other rights
              and grant no other rights or licenses, implied or otherwise.{" "}
              <br /> <br />
              • You may not do, or try to do, the following: (a) access or
              tamper with non-public areas of the Services, our computer
              systems, or the systems of our technical providers; (b) access or
              search the Services by any means other than the currently
              available, published interfaces (e.g., APIs) that we provide; (3)
              forge any TCP/IP packet header or any part of the header
              information in any email or posting, or in any way use the
              Services to send altered, deceptive, or false source-identifying
              information; or (4) interfere with, or disrupt, the access of any
              user host, or network, including sending a virus, overloading,
              flooding, spamming, mail-bombing the Services, or by scripting the
              creation of content or accounts in such a manner as to interfere
              with or create an undue burden on the Services. <br /> <br />
              • You agree that we may access, store and use any information that
              you provide in accordance with the Terms of the Privacy Policy and
              your choices (including Settings). <br /> <br />
            </span>
            <p>
              By submitting suggestions or other feedback regarding our Services
              to GamesBox , you agree that gamesboxcan use and share (but
              does not have to) such feedback for any purpose without
              compensation to you.
            </p>
            <p>
              You agree to only provide content or information that does not
              violate the law nor anyone’s rights (including intellectual
              property rights). You also agree that your profile information
              will be truthful. gamesboxmay be required by law to remove
              certain information or content in certain countries. .
            </p>
            <span>3.4.7 Permitted Uses</span>
            <p>
              If you operate a search engine, web crawler, bot, scraping tool,
              data mining tool, bulk downloading tool, wget utility, or similar
              data gathering or extraction tool, you may access GamesBox ,
              subject to the following additional rules: a) you must be a
              descriptive user agent header; b) you must follow robots.txt at
              all times; c) your access must not adversely affect any aspect of
              gamesboxfunctioning; and d) you must make it clear how to
              contact you, either in your user agent string, or on your website
              if you have one.
            </p>
            <p>
              Additionally, these services should be handled keeping the risk of
              Intellectual property rights in mind. While crawling, scraping,
              mining data, you might be violating the rules of the services if
              the CC Attribution of the Content has not been followed.
            </p>
            <h4>3.4.8 No Endorsement or Verification.</h4>
            <p>
              Please note that gamesboxcontains access to third-party
              content, products and services, and it offers interactions with
              third parties. Participation or availability on gamesboxdoes
              not amount to endorsement or verification by us. We make no
              warranties or representations about the accuracy, completeness, or
              timeliness or any content posted on gamesboxby anyone.
            </p>
            <h4>3.5 Intellectual Property Rights</h4>
            <p>
              gamesboxreserves all of its intellectual property rights in the
              services. Using the services doesn’t give you any ownership in our
              services or the content or information made available through our
              services. Trademarks and logos used in connection with the
              services are be the Trademarks of their respective owner.
              gamesboxlogo and other Trademarks, service marks, graphics, and
              logos used for our services are Trademarks or registered
              Trademarks of GamesBox .
            </p>
            <p>
              If you believe content on gamesboxviolates GamesBox 
              Acceptable Use Policy or otherwise violates applicable law (apart
              from copyright or Trademark violation) or other GamesBox 
              policies, you may send us a mail, report or contact us to notify
              about such content.
            </p>
            <p>
              We have no obligation to delete content that you personally may
              find objectionable or offensive. We endeavor to respond promptly
              to requests for content removal, consistent with our policies and
              applicable law.
            </p>
            <h4>4 Integrated Service Provider</h4>
            <p>
              You may enable another online service provider, such as a social
              networking service (“Integrated Service Provider”), to be directly
              integrated into your account on GamesBox . By enabling an
              integrated service, you are allowing us to pass to, and receive
              from, the Integrated Service Provider your log-in information
              related to you and your use of integrated services within
              GamesBox , please see our Privacy Policy. Note that your use of
              any Integrated Service Provider and its own handling of your data
              and information is governed solely by their Terms of Use, Privacy
              Policies, and other policies.
            </p>
            <h4>5 More from GamesBox </h4>
            <h4>5.1 Digests</h4>
            <p>
              When you sign up for GamesBox , the service includes receipt of a
              digest of content and profile of some of the users and teams that
              may be of interest to you. You can opt-out of receipt of the
              digest, and adjust other communication Settings, by going to
              “Email and Notification” Settings in your account profile, as
              further described in our Privacy Policy.
            </p>
            <p>
              You may unsubscribe from receiving emails from us however we may
              still send you transactional emails or mail that we feel are
              important for you to continue using GamesBox .
            </p>
            <br />
            <p>
              5.2 Paid Services
              <br />
              gamesboxis dedicated to solve real life problems for Designers,
              and creative people. To do so, gamesboxprovides certain free
              and paid services (called as “Premium Services”), such as Online
              look book creator, posted jobs, royalty-based papers, articles and
              certain content that our users decide to be paid. If you use our
              Online Look Book Creator service you accept all the terms and
              conditions related to it.
            </p>
            <p>
              If you use of any Integrated Service Provider and its own handling
              of your data and information is governed solely by their Terms of
              Use. 5.3 Buttons, Links and Widgets
            </p>
            <p>
              You have permission to use GamesBox ’s buttons, links and
              widgets, subject to these Terms of Service (including the
              disclaimers and limitations of liability) and provided that : (a)
              your use of such buttons, links and widgets link only to
              GamesBox ; (b) you will not modify such buttons, links, or
              widgets or associated code in any manner; (c) you will not use any
              such buttons, links and widgets, and the website on which such
              buttons, links and widgets are used do not violate our Acceptable
              Use Policy.
            </p>
            <br />
            <p>
              5.4 Web Resources and Third-Party Services
              <br />
              May also offer you the opportunity to visit links to other
              websites or to engage with third-party products or services. You
              assume all risk arising out of your use of such websites or
              resources.
            </p>
            <h4>6 Disclaimers and Limitation of Liability</h4>
            <h4>
              PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY
              OF DESIGN FIST PRIVATE LIMITED TO YOU.
            </h4>
            <p>
              “GamesBox ” MEANS Design Fist PRIVATE LIMITED, AND ANY
              SUBSIDIARIES, AFFILIATES, RELATED COMPANIES, SUPPLIERS, LICENSOR
              AND PARTNERS, AND THE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND
              REPRESENTATIVES OF EACH OF THEM. EACH PROVISION BELOW APPLIES TO
              THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
            </p>
            <h4>6.1 No Warranty</h4>
            <p>
              TO THE EXTENT ALLOWED UNDER LAW, gamesboxAND ITS AFFILIATES
              (AND THOSE THAT gamesboxWORKS WITH TO PROVIDE THE SERVICES) (A)
              DISCLAIM ALL IMPLIED WARRANTIES AND REPRESENTATIONS (E.G.
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              ACCURACY OF DATA, AND NON-INFRINGEMENT); (B) DO NOT GUARANTEE THAT
              THE SERVICES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS, AND (C)
              PROVIDE THE SERVICE (INCLUDING CONTENT AND INFORMATION) ON AN “AS
              IS” AND “AS AVAILABLE” BASIS.
            </p>
            <p>
              SOME LAWS DO NOT ALLOW CERTAIN DISCLAIMERS, SO SOME OR ALL OF
              THESE DISCLAIMERS MAY NOT APPLY TO YOU.
            </p>
            <h4>6.2 Exclusion of Liability</h4>
            <p>
              TO THE EXTENT PERMITTED UNDER LAW (AND UNLESS gamesboxHAS
              ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS
              CONTRACT), gamesboxAND ITS AFFILIATE (AND THOSE THAT GamesBox 
              WORKS WITH TO PROVIDE THE SERVICES) SHALL NOT BE LIABLE TO YOU OR
              OTHERS FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
              PUNITIVE DAMAGES, OR ANY LOSS OF DATA, OPPORTUNITIES, REPUTATION,
              PROFITS OR REVENUES, RELATED TO THE THE SERVICES (E.G. OFFENSIVE
              OR DEFAMATORY STATEMENTS, DOWN TIME OR LOSS, USE OF, OR CHANGES
              TO, YOUR INFORMATION OR CONTENT).
            </p>
            <p>
              gamesboxMAKES NO PROMISES WITH RESPECT TO, AND EXPRESSLY
              DISCLAIMS ALL LIABILITY FOR: (A) CONTENT POSTED BY ANY USER OR
              THIRD PARTY; (B) ANY THIRD-PARTY WEBSITE, THIRD-PARTY PRODUCT, OR
              THIRD-PARTY SERVICE LISTED ON OR ACCESSIBLE TO YOU THROUGH
              GamesBox , INCLUDING AN INTEGRATED SERVICE PROVIDER OR
              PROFESSIONAL CONTRIBUTOR; (C) THE QUALITY OR CONDUCT OF ANY THIRD
              PARTY YOU ENCOUNTER IN CONNECTION WITH YOUR USE OF GamesBox ; OR
              (D) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT.
            </p>
            <p>
              YOUR SOLE REMEDY FOR DISSATISFACTION WITH gamesboxIS TO STOP
              USING GamesBox .
            </p>
            <p>
              WITHOUT LIMITING THE FOREGOING, GamesBox ’s MAXIMUM AGGREGATE
              LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN
              CONNECTION WITH gamesboxOR THIS AGREEMENT IS LIMITED TO THE
              AMOUNT PAID TO gamesboxIN CONNECTION WITH gamesboxIN THE SIX
              (6) MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY.
            </p>
            <p>
              THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE BARGAIN
              BETWEEN YOU AND gamesboxAND SHALL APPLY TO ALL CLAIMS OF
              LIABILITY (E.G. WARRANTY, TORT, NEGLIGENCE, CONTRACT, LAW) AND
              EVEN IF gamesboxOR ITS AFFILIATES HAS BEEN TOLD OF THE
              POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL
              THEIR ESSENTIAL PURPOSE.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW LIMITATION ON IMPLIED WARRANTIES
              OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. AS A
              RESULT, THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU
              IN WHOLE OR IN PART.
            </p>
            <h4>7 Indemnification</h4>
            <p>
              You agree to release, indemnify, and defend gamesboxfrom all
              third-party claims and costs (including reasonable attorneys’
              fees) arising out of or related to: i) your use of GamesBox , ii)
              Your Content, iii) your conduct or interactions with other users
              of GamesBox , or iv) your breach of any part of this Agreement.
              We will promptly notify you of any such claim and will provide you
              (at your expense) with reasonable assistance in defending the
              claim. You will allow us to participate in the defense and will
              not settle any such claim without our prior written consent. We
              reserve the right, at our own expense, to assume the exclusive
              defense of any matter otherwise subject to indemnification by you.
              In that event, you will have no further obligation to defend us in
              that matter.
            </p>
            <h4>8 Termination</h4>
            <p>
              Both you and gamesboxmay terminate this contract at any time
              with notice to the other. On termination, you lose the right to
              access or use the Services. The following shall survive
              termination:
            </p>
            <span>
              • Our rights to use and disclose your feedback; <br /> <br />
              • User and/or Visitors’ rights to further re-share content and
              information you shared through the Service to the extent copied or
              re-shared prior to termination; <br /> <br />
              • Sections 6, 7, 9, 10 of this Contract; <br /> <br />
              • Any amounts owed by either party prior to termination remain
              owed after termination. <br /> <br />
              <h4>9 Dispute Resolution</h4>
              <p>
                This Agreement and any action arising out of your use of
                gamesboxwill be governed by the laws of NCT, India without
                regard to or application of its conflict of law provisions or
                your state or country of residence. We both agree that all of
                these claims can only be litigated in NCT, India, and we each
                agree to personal jurisdiction of the courts located in NCT,
                India.
              </p>
              <h4>10 General Terms</h4>
              <p>
                If a court with authority over this Contract finds any part of
                it not enforceable, you and us agree that the court should
                modify the terms to make that part enforceable while still
                achieving its intent. If the court cannot do that, you and we
                agree to ask the court to remove that unenforceable part and
                still enforce the rest of this Contract. To the extent allowed
                by law, the English language version of this Contract (including
                additional terms that may be provided by us when you engage with
                a feature of the Services) is the only agreement between us
                regarding the Services and supersedes all prior agreements for
                the Services.
              </p>
              <p>
                If we don’t act to enforce a breach of this Contract, that does
                not mean that gamesboxhas waived its right to enforce this
                Contract. You may not assign or transfer this Contract (or your
                membership or use of Services) to anyone without out consent.
                However, you agree that gamesboxmay assign this Contact to
                its affiliates or a party that buys it without your consent.
                There are no third-party beneficiaries to this Contract.
              </p>
              <p>
                If you access GamesBox , you acknowledge that this Agreement is
                between you and gamesboxonly, and not with another
                application service provider or application platform provider
                (such as Apple Inc. or Google Inc.), which may provide you the
                application subject to its own terms. To the extent you access
                gamesboxthrough a mobile device, your wireless carrier’s
                standard charges, data rates, and other fees may apply.
              </p>
              <p>
                You may not assign or transfer this Agreement (or any or your
                rights or obligations under this Agreement) without our prior
                written consent; any attempted assignment or transfer without
                complying with the foregoing will be void. We may freely assign
                or transfer this Agreement. This Agreement inures to the benefit
                of and is binding upon the parties and their respective legal
                representatives, successors, and assigns.
              </p>
              <p>
                This agreement does not create a joint venture, agency,
                partnership, or other form of joint enterprise between you and
                us. Except as expressly provided herein, neither party has the
                right, power, or authority to create any obligation or duty,
                express or implied, on behalf of the other.
              </p>
              <p>
                No waiver of any terms will be deemed a further or continuing
                waiver of such term or any other term. Our failure assert a
                right or provision under this Agreement will not constitute a
                waiver of such right or provision.
              </p>
              <p>
                We reserve the right to change the terms of this Contract and
                will provide you notice if we do and we agree that changes
                cannot be retroactive. If you don’t agree to these changes, you
                must stop using the Services.
              </p>
              <p>
                You agree that the only way to provide us legal notice or do any
                kind of legal communication is at the addresses provided Section
                12. We do not guarantee to reply to any other mode of
                communication.
              </p>
              <h4>11 Contact Us</h4>
              <p>
                If you want to send us notices or services of process, please
                contact us:
              </p>
              <br />
              <p>ONLINE or by MAIL</p>
            </span>
          </div>
          <div className="container__nav">
            <small>
              By clicking 'Accept' you are agreeing to our terms and conditions.
            </small>
            <a className="button" href="#">
              Accept
            </a>
          </div>
        </section>
      </main>
    </>
  );
};

export default Trem;
