import React from 'react';
import { InputGroup,Card } from '@blueprintjs/core';
import { SectionTab } from 'polotno/side-panel';
// import useSWR from 'swr';
import FaFont from '@meronex/icons/fa/FaFont';
import Paidpopup from './Paidpopup';

export const TextPanel = ({ store }) => {
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    let skipResults = false;

    const run = async () => {
      setLoading(true);

      const req = await fetch(
        `https://gamesbox.in/fontsjson/getfonts.php`
      );
      const json = await req.json();
      if (skipResults) {
        return;
      }
      setLoading(false);
      setItems(json);
    };
    run();
    return () => {
      skipResults = true;
    };
  }, []);


 return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* <InputGroup
      value={query} 
        leftIcon="search"
        placeholder="Search"
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        style={{
          marginBottom: '20px',
        }}
      /> */}
      <h3 style={{color: "white", textAlign: "center"}}>My Fonts</h3>

      {loading && <div style={{ padding: '30px 10px' }}>Loading...</div>}
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          display: loading ? 'none' : 'block',
        }}
      >
 
        
      {items.map((item) =>{
        return(
           <Card
           key={item.id}
           interactive
           style={{ margin: '10px' }}
           onClick={() => {
            if(item.paid == "no"){
                setShowPopUp(true)
            }
            else{
                const width = 500;
             const textEl = store.activePage.addElement({
               type: 'text',
               text: item.text,
               width: width,
               x: store.width / 2 - width / 2,
               y: store.height / 2 - 100,
               fontFamily: item.name,
               fontSize: 30,
             });
            }

            
             
           }}
         >
           <div  style={{ fontFamily: item.name, fontSize: "30px", textAlign: "center" }}>{item.name}</div>
           <div style={{ textAlign: 'right' }}>{item.author}</div>
         </Card>
        )
      })}
      </div>
     
      {showPopUp && <Paidpopup/>}
    </div>
  );
};

// // define the new custom section
export const TextsSection = {
  name: 'Fonts',
  Tab: (props) => (
    <SectionTab name="Fonts" {...props}>
      <FaFont />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: TextPanel,
};