import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import { Outlet, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
toast.configure();
const customStyles = {
  overlay: {
    position: "static",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    backgroundColor: "transparent !important",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

const Login = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  window.onclick = function (event) {
    setIsOpen(true);
  };
  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    const postdata = { email: uname.value, password: pass.value };

    axios
      .post("https://gamesbox.in/designfistapi/test.php?login", postdata)
      .then((res) => {
        if (res.data.message == "Login Successfully") {
          toast.success(res.data.message);
          console.log(res.data.userid);
          sessionStorage.setItem("loginsession", res.data.userid);
          sessionStorage.setItem("membershipsession", res.data.paid);
          window.location.reload();

        } else {
          toast.error(res.data.message);
        }
      })

      .catch((error) => {
        console.log(error);
      });
    // Find user login info
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div style={{ height: "100vh", backgroundColor: "white" }}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          transparent={true}
          contentLabel="Example Modal"
        >
          <form onSubmit={handleSubmit}>
            <h2 align="center">gamesboxLogin</h2>
            <div className="input-container">
              <label>Email </label>
              <input type="text" name="uname" required />
            </div>
            <div className="input-container">
              <label>Password </label>
              <input type="password" name="pass" required />
            </div>
            <div className="button-container">
              <input type="submit" value="Login" />
            </div>
          </form>
        </Modal>
        <div className="privecy">
          <p>
            Click "Sign In" to agree to Medium's{" "}
            <Link to="/term">HomeTerms of Service</Link> and acknowldge that <br />{" "}
            Medium's  <Link to="/privacy">Privacy Policy</Link> applies to you.
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
