import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Workspace } from "polotno/canvas/workspace";
import { DEFAULT_SECTIONS, SidePanel } from "polotno/side-panel";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import React from "react";
import ReactDOM from "react-dom/client";
import App1 from "./App1";
import { loadFile } from "./file";
import axios from "axios";
import { IconsSection } from "./icons-section";
import { QrSection } from "./qr-section";
import { QuotesSection } from "./quotes-section";
import Paidpopup from "./Paidpopup";
import Coinpopup from "./Coinpopup";

import { PageControls } from "./components/page-controls";
import { ShapesSection } from "./shapes-section";
import { TextsSection } from "./text-section";
import { StableDiffusionSection } from "./stable-diffusion-section";
import { TemplatesSection } from "./templates-panel";
import Topbar from "./topbar";
import { useState, useRef, useEffect } from "react";
import { getOffsetLeft, getOffsetTop } from "@mui/material";
import "@blueprintjs/core/lib/css/blueprint.css";
import {
  FaPen,
  FaCircle,
  FaSquare,
  FaRuler,
  FaSprayCan,
  FaPlay,
  FaShapes,
} from "react-icons/fa";
import { FiSun, FiCircle } from "react-icons/fi";
import "./App.css";

const sections = [TemplatesSection, ...DEFAULT_SECTIONS];
const root = ReactDOM.createRoot(document.getElementById("root"));
// DEFAULT_SECTIONS.splice(3, 0, IllustrationsSection);
// replace elements section with just shapes
sections.push(ShapesSection);

// DEFAULT_SECTIONS.splice(2, 0, StableDiffusionSection);
// add icons
//add texts section means fontsection
sections.splice(3, 0, TextsSection, IconsSection);
// add two more sections
sections.push(QuotesSection, QrSection);

sections.push(StableDiffusionSection);

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const App = ({ store }) => {
  const [balance, setBalance] = React.useState();

  const check = sessionStorage.getItem("trans");

  const [checked, setChecked] = React.useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPaidPopup, setShowPaidPopup] = useState(false);
  const [penToolActive, setPenToolActive] = useState(false);

  const membership = sessionStorage.getItem("membershipsession");
  console.log("membership: ", membership);

  const handleChange = () => {
    const textdata = sessionStorage.getItem("textdata");
    console.log(textdata);
    if (membership === "yes") {
      root.render(
        <React.StrictMode>
          <App1 text1={textdata} />
        </React.StrictMode>
      );
    } else {
      setShowPaidPopup(true);
    }
  };

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  const height = useHeight();
  const userid = sessionStorage.getItem("loginsession");
  const postdata = { userid: userid };
  const handleAddPageClick = (page) => {
    if (balance >= 5) {
      const newPage = store.addPage({
        bleed: store.activePage?.bleed || 0,
      });
      const index = store.pages.indexOf(page);
      newPage.setZIndex(index + 1);
      setBalance(balance - 5);
      axios
        .post(
          "https://gamesbox.in/designfistapi/test.php?balancededuct",
          postdata
        )
        .then((res) => {})

        .catch((error) => {
          console.log(error);
        });
    } else {
      setShowPopup(true);
    }
  };

  const [color, setColor] = useState("black");
  const [toolSize, setSize] = useState(1);
  const [activeTool, setActiveTool] = useState("pen");
  const [toolSettings, setToolSettings] = useState({});

  const handlePenToolToggle = () => {
    setPenToolActive(!penToolActive);
    // setIsDrawing(!isDrawing)
    console.log(penToolActive);
  };

  const toolSettingsMap = {
    pen: { size: 1 },
    arc: { arcRadius: 10 * toolSize, startAngle: 90, endAngle: Math.PI * 2 },
    circle: { radius: 10 * toolSize },
    concentricCircle: { innerRadius: 40, outerRadius: 60 },
    graphPencil: { size: 2 },
    radialLine: { length: 100, angle: 0 },
    rectangle: { Width: 80, Height: 60 },
    spray: { Size: toolSize, density: 30 },
    triangle: { SIZE: toolSize * 2 },
    Strip: { size: toolSize * 3 },
    // Add more tools and their settings here
  };

  const handleDrawing = (canvas, tool, settings) => {
    let lastX, lastY;

    const ctx = canvas.getContext("2d");

    const startDrawing = (event) => {
      const rect = canvas.getBoundingClientRect();
      [lastX, lastY] = [event.clientX - rect.left, event.clientY - rect.top];
    };

    const draw = (event) => {
      const rect = canvas.getBoundingClientRect();
      const currentX = event.clientX - rect.left;
      const currentY = event.clientY - rect.top;

      ctx.beginPath();

      switch (tool) {
        case "pen":
          ctx.moveTo(lastX, lastY);
          ctx.lineTo(currentX, currentY);
          ctx.strokeStyle = color;
          ctx.lineWidth = settings.size;
          ctx.lineCap = "round";
          ctx.lineJoin = "round";
          ctx.stroke();
          break;

        case "arc":
          const { arcRadius, startAngle, endAngle } = settings;
          ctx.arc(lastX, lastY, arcRadius, startAngle, endAngle);
          ctx.strokeStyle = color;
          ctx.lineWidth = toolSize;
          ctx.stroke();
          break;

        case "circle":
          const { radius } = settings;
          ctx.arc(lastX, lastY, radius, 0, Math.PI * 2);
          ctx.strokeStyle = color;
          ctx.lineWidth = toolSize;
          ctx.stroke();
          break;

        case "concentricCircle":
          const { innerRadius, outerRadius } = settings;
          ctx.arc(lastX, lastY, innerRadius, 0, Math.PI * 2);
          ctx.arc(lastX, lastY, outerRadius, 0, Math.PI * 2);
          ctx.strokeStyle = color;
          ctx.lineWidth = toolSize;
          ctx.stroke();
          break;

        case "graphPencil":
          ctx.moveTo(lastX, lastY);
          ctx.lineTo(currentX, currentY);
          ctx.strokeStyle = color;
          ctx.lineWidth = settings.size;
          ctx.lineCap = "square";
          ctx.lineJoin = "miter";
          ctx.stroke();
          break;

        case "radialLine":
          const { length, angle } = settings;
          const endX = lastX + length * Math.cos(angle);
          const endY = lastY + length * Math.sin(angle);
          ctx.moveTo(lastX, lastY);
          ctx.lineTo(endX, endY);
          ctx.strokeStyle = color;
          ctx.lineWidth = toolSize;
          ctx.stroke();
          break;

        case "rectangle":
          const { Width, Height } = settings;
          ctx.rect(lastX, lastY, Width, Height);
          ctx.strokeStyle = color;
          ctx.lineWidth = toolSize;
          ctx.stroke();
          break;

        case "spray":
          const { Size, density } = settings;
          const area = Math.PI * (Size / 2) ** 2;
          const dotsCount = density * area;
          for (let i = 0; i < dotsCount; i++) {
            const offsetX = (Math.random() - 0.5) * Size;
            const offsetY = (Math.random() - 0.5) * Size;
            ctx.fillRect(lastX + offsetX, lastY + offsetY, 1, 1);
          }
          break;

        case "triangle":
          const { SIZE } = settings;
          const HEIGHT = (Math.sqrt(3) * SIZE) / 2;
          const HalfSize = SIZE / 2;
          ctx.moveTo(lastX, lastY - HEIGHT / 2);
          ctx.lineTo(lastX - HalfSize, lastY + HEIGHT / 2);
          ctx.lineTo(lastX + HalfSize, lastY + HEIGHT / 2);
          ctx.closePath();
          ctx.strokeStyle = color;
          ctx.lineWidth = SIZE;
          ctx.stroke();
          break;

        default:
          break;
      }

      [lastX, lastY] = [currentX, currentY];
    };

    const stopDrawing = () => {
      setPenToolActive(false);
      setActiveTool(null);
      canvas.removeEventListener("mousedown", startDrawing);
      canvas.removeEventListener("mousemove", draw);
      canvas.removeEventListener("mouseup", stopDrawing);
      canvas.removeEventListener("mouseout", stopDrawing);
    };

    canvas.addEventListener("mousedown", startDrawing);
    canvas.addEventListener("mousemove", draw);
    canvas.addEventListener("mouseup", stopDrawing);
    canvas.addEventListener("mouseout", stopDrawing);
  };

  useEffect(() => {
    const canvasDiv = document.querySelector(".konvajs-content");
    const canvas = canvasDiv?.querySelector("canvas");

    const handleCanvasClick = (event) => {
      if (penToolActive && activeTool) {
        handleDrawing(canvas, activeTool, toolSettings);
      }
    };

    // Add click event listener to the canvas
    canvas.addEventListener("click", handleCanvasClick);

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      canvas.removeEventListener("click", handleCanvasClick);
    };
  }, [color, toolSize, penToolActive, activeTool, toolSettings]);

  const handleToolChange = (tool) => {
    setActiveTool(tool);
    setToolSettings(toolSettingsMap[tool]);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(Number(event.target.value));
  };
  return (
    <div
      style={{
        width: "100vw",
        height: height + "px",
        display: "flex",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
    >
      <Topbar store={store} balance={balance} setBalance={setBalance} />

      <div style={{ height: "calc(100% - 50px)" }}>
        <PolotnoContainer
          className="polotno-app-container"
          style={{ width: "100vw", height: "100vh" }}
        >
          <SidePanelWrap class="bp5-dark">
            <SidePanel
              store={store}
              sections={sections}
              defaultSection={"custom-templates"}
            />
          </SidePanelWrap>

          <WorkspaceWrap>
            <Toolbar store={store} />
            <div
              style={{
                padding: "5px",
                overFlow: "hidden",
                display: "flex",
                flexDirection: "row",
                gap: "30px",
                backgroundColor: "white",
                alignItems: "center"
              }}
            >
              <label style={{ fontSize: "20px", padding: 4, marginTop: 4 }}> Transliteration</label>

              <input
                style={{
                  // position: "absolute",
                  width: "20px",
                  // marginLeft: "13%",
                  height: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
                type="checkbox"
                value={checked}
                checked={checked}
                onClick={handleChange}
              />

              <input
                type="color"
                id="colorPicker"
                value={color}
                onChange={handleColorChange}
                style={{ width: "40px", marginLeft: "5px", cursor: "pointer", borderRadius: 5, boxShadow: 2 }}
              />

              <input
                style={{ width: "100px", borderRadius: 5, cursor: "pointer"}}
                type="range"
                id="sizeSlider"
                min={1}
                max={10}
                value={toolSize}
                onChange={handleSizeChange}
              />

              <button
                style={{
                  padding: 5,
                  backgroundColor: "white",
                  cursor: "pointer",
                  borderRadius: 5,
                  boxShadow: 2,
                  color: "black"
                }}
                onClick={handlePenToolToggle}
              >
                pentool
              </button>
              {penToolActive && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {/* Pen Tool Button */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("pen")}
                  >
                    <FaPen />
                  </button>
                  {/* arc tool */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("arc")}
                  >
                    <p>⌒</p>
                  </button>
                  {/* Circle Tool Button */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("circle")}
                  >
                    <FaCircle />
                  </button>

                  {/* Concentric Circle Tool Button */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("concentricCircle")}
                  >
                    <p>◎</p>
                  </button>

                  {/* Graph Pencil Tool Button */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("graphPencil")}
                  >
                    <p>✎</p>
                  </button>
                  {/* radial line */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("radialLine")}
                  >
                    <FiSun />
                  </button>
                  {/* Spray Tool Button */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("spray")}
                  >
                    <FaSprayCan />
                  </button>

                  {/* Triangle Tool Button */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("triangle")}
                  >
                    <FaPlay />
                  </button>

                  {/* Rectangle Tool Button */}
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      color: "black",
                      fontSize: 18,
                    }}
                    onClick={() => handleToolChange("rectangle")}
                  >
                    <p>■</p>
                  </button>
                  {/* <button onClick={() => handleToolChange('bezier')}>
  Bezier 
</button> */}

                  {/* ... */}
                </div>
              )}

              
            </div>
            <Workspace
              store={store}
              components={{
                PageControls: (props) => (
                  <PageControls
                    {...props}
                    handleAddPageClick={handleAddPageClick}
                  />
                ),
              }}
            />

            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>

        {showPopup && <Coinpopup />}
        {showPaidPopup && <Paidpopup />}
      </div>
    </div>
  );
};

export default App;
