import React, { useState } from "react";
import axios from "axios";
import { ReactTransliterate } from "react-transliterate";

import "./index1.css";

const App1 = ({ text1 }) => {
  const [text, setText] = React.useState(text1);
  const [lang, setLang] = useState("hi");
  const langs = [
    { label: "Amharic", value: "am" },
    { label: "Arabic", value: "ar" },
    { label: "Bangla", value: "bn" },
    { label: "Belarusian", value: "be" },
    { label: "Bulgarian", value: "bg" },
    { label: "Chinese (Hong Kong)", value: "yue-hant" },
    { label: "Chinese (Simplified)", value: "zh" },
    { label: "Chinese (Traditional)", value: "zh-hant" },
    { label: "French", value: "fr" },
    { label: "German", value: "de" },
    { label: "Greek", value: "el" },
    { label: "Gujarati", value: "gu" },
    { label: "Hebrew", value: "he" },
    { label: "Hindi", value: "hi" },
    { label: "Italian", value: "it" },
    { label: "Japanese", value: "ja" },
    { label: "Kannada", value: "kn" },
    { label: "Malayalam", value: "ml" },
    { label: "Marathi", value: "mr" },
    { label: "Nepali", value: "ne" },
    { label: "Odia", value: "or" },
    { label: "Persian", value: "fa" },
    { label: "Portuguese (Brazil)", value: "pt" },
    { label: "Punjabi", value: "pa" },
    { label: "Russian", value: "ru" },
    { label: "Sanskrit", value: "sa" },
    { label: "Serbian", value: "sr" },
    { label: "Sinhala", value: "si" },
    { label: "Spanish", value: "es" },
    { label: "Tamil", value: "ta" },
    { label: "Telugu", value: "te" },
    { label: "Tigrinya", value: "ti" },
    { label: "Ukrainian", value: "uk" },
    { label: "Urdu", value: "ur" },
    { label: "Vietnamese", value: "vi" }
  ];



  // const setItem = () => {
  //   axios.get("https://gamesbox.in/designfistapi/test.php?getjson")
  //     .then(res => {
  //       const textid = sessionStorage.getItem('textid');
  //       const pageid = sessionStorage.getItem('pageid');
  //       const postdata = { text: text, textid: textid, pageid: pageid };
  //       axios.post("https://gamesbox.in/designfistapi/test.php?savedata", postdata)
  //         .then(res => {
  //           console.log(res.data.message);
  //           if (res.data.message == "Updated Successfully") {
  //             window.location.reload();
  //           }
  //         })
  //         .catch(error => {
  //           console.log(error);
  //         });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  const setItem = () => {


    const textid = sessionStorage.getItem('textid');
   const pageid = sessionStorage.getItem('pageid');
  



const json = JSON.parse(sessionStorage.getItem("polotno-state"));

const targetPageId = pageid; // Replace with the ID of the desired page
const targetChildId = textid; // Replace with the ID of the desired child

const pages = json?.pages;
if (pages) {
  const targetPage = pages.find(page => page.id === targetPageId);
  if (targetPage) {
    const targetChild = targetPage.children.find(child => child.id === targetChildId);
    if (targetChild) {
      targetChild.text = targetChild.text.replace(/[\s\S]*/, text);
    }
  }
}
  // Store the updated JSON back to sessionStorage
  sessionStorage.setItem("polotno-state", JSON.stringify(json));
  window.location.reload()

    
  };

  const changeLanguage = (e) => {
    setLang(e.target.value);
    console.log(e.target.value);
  };


  const clearText = () => {
    setText("");
    window.location.reload();
  };

  return (
    <>
      <div className='containerTran'>
        <select
          className="language-dropdown"
          value={lang}
          onChange={changeLanguage}
        >
          {langs.map((l) => (
            <option key={l.value} value={l.value}>
              {l.label}
            </option>
          ))}
        </select>
        <br />
        <ReactTransliterate

          value={text}
          onChangeText={(text) => {
            setText(text);
          }}
          lang={lang}
          
          className="transliterate"
        />
        <button onClick={setItem} className="button">Export</button>
        <button onClick={clearText} className="button">Clear</button>
      </div>
    </>
  );
}
export default App1;