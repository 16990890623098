import React from "react";
import "./coinpopup.css";
import { AiOutlineClose } from "react-icons/ai";

const Coinpopup = ({ setClosePopup }) => {
    const displayRazorpay = (amount) => {
        const name = "Designfist"
        const email = sessionStorage.getItem("email")

        window.location.href=`https://gamesbox.in/razorpayment/index.php?name=${name}&email=${email}&amount=${amount}`;
    }

	return (
		<div className="coin-popup">
			<div className="coin-popup-inner">
				<div className="popup-close-div" style={{ textAlign: "center" }}>
					<h1 style={{ color: "white" }}>Upgrade to Designfist Pro</h1>
					<AiOutlineClose
						onClick={() => setClosePopup(false)}
						className="icon"
						style={{cursor: "pointer",fontSize: "30px",color: "white"}}
					/>
				</div>
				<div className="topconatainer mt-4 mb-4">
					<div className="pop-div1 one">
						<h3>Basic (per/day)</h3>
						<h2>₹10</h2>
						<p>Access to 100 pages</p>
						<p>Transliteration</p>

						
						<button onClick={() => displayRazorpay(35)}>
							Book Now
						</button>
					</div>
					<div className="pop-div1 two">
						<h3>Standard (per/month)</h3>
						<h2>₹20</h2>
						<p>Access to 200 pages</p>
						<p>Transliteration</p>

						
						<button onClick={() => displayRazorpay(299)}>
							Book Now
						</button>
					</div>
                    <div className="pop-div1 three">
						<h3>Premium (per/month)</h3>
						<h2>₹30</h2>
						<p>Access to 300 pages</p>
						<p>Transliteration</p>

						<button onClick={() => displayRazorpay(299)}>
							Book Now
						</button>
					</div>
                    <div className="pop-div1 four">
						<h3>Exclusive (per/month)</h3>
						<h2>₹40</h2>
						<p>Access to 400 pages</p>
						<p>Transliteration</p>

						
						<button onClick={() => displayRazorpay(299)}>
							Book Now
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Coinpopup
