import React, { useState } from "react";
import "./privacy.css";
const Privacy = () => {
  const [active, setActive] = useState(1);

  console.log();
  return (
    <>
      <div className="wrapper flex_align_justify">
        <div className="tc_wrap">
          <div className="tabs_list">
            <ul>
              <li
                data-tc="tab_item_1"
                className="active"
                onClick={() => {
                  setActive(1);
                }}
              >
                {" "}
                Do's and Dont's{" "}
              </li>
              <li
                data-tc="tab_item_2"
                onClick={() => {
                  setActive(2);
                }}
              >
                CopyRight Policy
              </li>
              <li
                data-tc="tab_item_3"
                onClick={() => {
                  setActive(3);
                }}
              >
                Community Policies
              </li>
              <li
                data-tc="tab_item_4"
                onClick={() => {
                  setActive(4);
                }}
              >
                Acceptable use policy
              </li>
              <li
                data-tc="tab_item_5"
                onClick={() => {
                  setActive(5);
                }}
              >
                Tradmark Olicy
              </li>
              <li
                data-tc="tab_item_6"
                onClick={() => {
                  setActive(6);
                }}
              >
                Privacy Policy
              </li>
            </ul>
          </div>
          <div className="tabs_content">
            <div className="tab_head">
              <h2>Privacy &amp; Policy</h2>
            </div>
            <div className="tab_body">
              {/* page 1 */}
              {active == 1 ? (
                <div className="tab_item tab_item_1">
                  <h3>Do's and Dont's</h3>
                  <p>
                    In order to provide a space where everyone feels comfortable
                    in sharing their work discuss their ideas and share among
                    others, all gamesboxusers should follow gamesboxkey
                    principles, gamesboxPolicies, and gamesboxTerms of
                    Service. We have created a list of Do’s and Dont’s for the
                    users to make each gamesboxpage useful and a great
                    community for everyone.
                  </p>
                  <h4>1. Do's</h4>
                  <h5>You agree that you will:</h5>
                  <span>
                    • Comply with all applicable laws, including, without
                    limitation, Privacy laws, intellectual property laws,
                    anti-spam laws, export control laws, tax laws, and
                    regulatory requirements; <br />
                    <br />• Provide accurate information to us and keep it
                    updated; <br />
                    <br />
                    • Use your real name on your profile; <br />
                    <br />
                    • Use the Services in a professional manner. <br />
                    <br />
                    • Use gamesboxto provide constructive feedback to the
                    fellow users. <br />
                    <br />
                    • Use precise and concise language in the work you publish
                    on this platform. <br />
                    <br />
                  </span>

                  <h4>2. Don'ts</h4>
                  <h5>You agree that you will not:</h5>

                  <span>
                    • Act in an unlawful or unprofessional manner in connection
                    with our Services, including being dishonest, abusive or
                    discriminatory; <br />
                    <br />
                    • Post inaccurate, defamatory obscene, shocking, hateful,
                    threatening or otherwise inappropriate content or airing
                    personal grievances or disputes; <br />
                    <br />
                    • Use an image that is not your likeness or a head-shot
                    photo for your profile; <br />
                    <br />
                    • Create a false identity on GamesBox . The occasional
                    creation of clearly fictional profiles by gamesboxor with
                    its express permission in connection with a promotional
                    campaign does not waive this obligation; <br />
                    <br />
                    • Misrepresent your identity (e.g. by using a pseudonym),
                    your current or previous positions, qualifications, works,
                    or affiliations with a person or entity; <br />
                    <br />
                    • Create a User profile for anyone other than yourself (a
                    real person); <br />
                    <br />
                    • Invite people you do not know to join your network; <br />
                    <br />
                    • Use or attempt to use another’s account; <br />
                    <br />
                    • Harass, abuse or harm another person; <br />
                    <br />
                    • Send or post any unsolicited or unauthorized advertising,
                    “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or
                    any form of solicitation unauthorized by GamesBox ; <br />
                    <br />
                    • Develop, support or use software, devices, scripts,
                    robots, or any other means or processes (including crawlers,
                    browser plugins and add-ons, or any other technology or
                    manual work) to scrape the Services or otherwise copy
                    profiles and other data from the Services without our
                    permission; <br />
                    <br />
                    • Bypass or circumvent any access controls or Service use
                    limits (such as caps on keyword searches); <br />
                    <br />• Copy, use, disclose or distribute any information
                    obtained from the Services, whether directly or through
                    third parties (such as search engines), without the consent
                    of GamesBox ; <br />
                    <br />
                    • Solicit email addresses or other personal information from
                    Members you don’t know, without authorization. <br />
                    <br />
                    • Use, disclose or distribute any data obtained in violation
                    of this policy; <br />
                    <br />
                    • Disclose information that you do not have the consent to
                    disclose (such as confidential information of others
                    (including your employer/team members)); <br />
                    <br />
                    • Violate the intellectual property rights of others,
                    including copyrights, patents, Trademarks, trade secrets, or
                    other proprietary rights. For example, do not copy or
                    distribute (except through the available sharing
                    functionality) the posts or other content of others without
                    their permission, which they may give by posting under a
                    Creative Commons license; <br />
                    <br />
                    • Violate the intellectual property or other rights of
                    DesigFist, including, without limitation, (i) copying or
                    distributing our learning videos or other materials or (ii)
                    copying or distributing our technology, unless it is
                    released under open source licenses; (iii) using the word
                    “GamesBox ” or “DF” our logos in any business name, email,
                    or URL <br />
                    <br />
                    • Use gamesboxinvitations to send messages to people who
                    don’t know you or who are unlikely to recognize you as a
                    known contact; <br />
                    <br />
                    • Post anything that contains software viruses, worms, or
                    any other harmful code; <br />
                    <br />
                    • Manipulate identifiers in order to disguise the origin of
                    any message or post transmitted through the Services. <br />
                    <br />
                    • Create profiles or provide content that promotes escort
                    services or prostitution. <br />
                    <br />
                    • Create or operate a pyramid scheme, fraud or other similar
                    practice; <br />
                    <br />
                    • Reverse engineer, decompile, disassemble, decipher or
                    otherwise attempt to derive the source code for the Services
                    or any related technology that is not open source; <br />
                    <br />
                    • Imply or state that you are affiliated with or endorsed by
                    gamesboxwithout our express consent (e.g., representing
                    yourself as an accredited gamesboxtrainer); <br />
                    <br />
                    • Rent, lease, loan, trade, sell/re-sell access to the
                    Services or related data; <br />
                    <br />
                    • Sell, sponsor, or otherwise monetize any Service without
                    GamesBox ’s consent; <br />
                    <br />
                    • Deep-link to our Services for any purpose other than to
                    promote your profile or a Group on our Services, without our
                    consent; <br />
                    <br />
                    • Remove any copyright, Trademark or other proprietary
                    rights notices contained in or on our Service; <br />
                    <br />
                    • Remove, cover or obscure any advertisement included on the
                    Services; <br />
                    <br />
                    • Use bots or other automated methods to access the
                    Services, add or download contacts, send or redirect
                    messages; <br />
                    <br />
                    • Monitor the Services’ availability, performance or
                    functionality for any competitive purpose; <br />
                    <br />
                    • Engage in “framing,” “mirroring,” or otherwise simulating
                    the appearance or function of the Services; <br />
                    <br />
                    • Overlaying or otherwise modifying the Services or their
                    appearance; <br />
                    <br />
                    • Access the Services except through the interfaces
                    expressly provided by GamesBox , such as its mobile
                    applications, competitions; <br />
                    <br />• Use a Service for tasks that it is not intended for;{" "}
                    <br />
                    <br />
                    • Override any security feature of the Services; <br />
                    <br />
                    • Interfere with the operation of, or place an unreasonable
                    load on, the Services (e.g., spam, denial of service attack,
                    viruses, gaming algorithms); and/or <br />
                    <br />
                    • Violate the Community Guidelines or any additional terms
                    concerning a specific Service that are provided when you
                    sign up for or start using such Service. <br />
                    <br /> • Act in an unlawful or unprofessional manner in
                    connection with our Services, including being dishonest,
                    abusive or discriminatory; <br />
                    <br />
                    • Post inaccurate, defamatory obscene, shocking, hateful,
                    threatening or otherwise inappropriate content or airing
                    personal grievances or disputes; <br />
                    <br />
                    • Use an image that is not your likeness or a head-shot
                    photo for your profile; <br />
                    <br />
                    • Create a false identity on GamesBox . The occasional
                    creation of clearly fictional profiles by gamesboxor with
                    its express permission in connection with a promotional
                    campaign does not waive this obligation; <br />
                    <br />
                    • Misrepresent your identity (e.g. by using a pseudonym),
                    your current or previous positions, qualifications, works,
                    or affiliations with a person or entity; <br />
                    <br />
                    • Create a User profile for anyone other than yourself (a
                    real person); <br />
                    <br />
                    • Invite people you do not know to join your network; <br />
                    <br />
                    • Use or attempt to use another’s account; <br />
                    <br />
                    • Harass, abuse or harm another person; <br />
                    <br />
                    • Send or post any unsolicited or unauthorized advertising,
                    “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or
                    any form of solicitation unauthorized by GamesBox ; <br />
                    <br />
                    • Develop, support or use software, devices, scripts,
                    robots, or any other means or processes (including crawlers,
                    browser plugins and add-ons, or any other technology or
                    manual work) to scrape the Services or otherwise copy
                    profiles and other data from the Services without our
                    permission; <br />
                    <br />
                    • Bypass or circumvent any access controls or Service use
                    limits (such as caps on keyword searches); <br />
                    <br />• Copy, use, disclose or distribute any information
                    obtained from the Services, whether directly or through
                    third parties (such as search engines), without the consent
                    of GamesBox ; <br />
                    <br />
                    • Solicit email addresses or other personal information from
                    Members you don’t know, without authorization. <br />
                    <br />
                    • Use, disclose or distribute any data obtained in violation
                    of this policy; <br />
                    <br />
                    • Disclose information that you do not have the consent to
                    disclose (such as confidential information of others
                    (including your employer/team members)); <br />
                    <br />
                    • Violate the intellectual property rights of others,
                    including copyrights, patents, Trademarks, trade secrets, or
                    other proprietary rights. For example, do not copy or
                    distribute (except through the available sharing
                    functionality) the posts or other content of others without
                    their permission, which they may give by posting under a
                    Creative Commons license; <br />
                    <br />
                    • Violate the intellectual property or other rights of
                    DesigFist, including, without limitation, (i) copying or
                    distributing our learning videos or other materials or (ii)
                    copying or distributing our technology, unless it is
                    released under open source licenses; (iii) using the word
                    “GamesBox ” or “DF” our logos in any business name, email,
                    or URL <br />
                    <br />
                    • Use gamesboxinvitations to send messages to people who
                    don’t know you or who are unlikely to recognize you as a
                    known contact; <br />
                    <br />
                    • Post anything that contains software viruses, worms, or
                    any other harmful code; <br />
                    <br />
                    • Manipulate identifiers in order to disguise the origin of
                    any message or post transmitted through the Services. <br />
                    <br />
                    • Create profiles or provide content that promotes escort
                    services or prostitution. <br />
                    <br />
                    • Create or operate a pyramid scheme, fraud or other similar
                    practice; <br />
                    <br />
                    • Reverse engineer, decompile, disassemble, decipher or
                    otherwise attempt to derive the source code for the Services
                    or any related technology that is not open source; <br />
                    <br />
                    • Imply or state that you are affiliated with or endorsed by
                    gamesboxwithout our express consent (e.g., representing
                    yourself as an accredited gamesboxtrainer); <br />
                    <br />
                    • Rent, lease, loan, trade, sell/re-sell access to the
                    Services or related data; <br />
                    <br />
                    • Sell, sponsor, or otherwise monetize any Service without
                    GamesBox ’s consent; <br />
                    <br />
                    • Deep-link to our Services for any purpose other than to
                    promote your profile or a Group on our Services, without our
                    consent; <br />
                    <br />
                    • Remove any copyright, Trademark or other proprietary
                    rights notices contained in or on our Service; <br />
                    <br />
                    • Remove, cover or obscure any advertisement included on the
                    Services; <br />
                    <br />
                    • Use bots or other automated methods to access the
                    Services, add or download contacts, send or redirect
                    messages; <br />
                    <br />
                    • Monitor the Services’ availability, performance or
                    functionality for any competitive purpose; <br />
                    <br />
                    • Engage in “framing,” “mirroring,” or otherwise simulating
                    the appearance or function of the Services; <br />
                    <br />
                    • Overlaying or otherwise modifying the Services or their
                    appearance; <br />
                    <br />
                    • Access the Services except through the interfaces
                    expressly provided by GamesBox , such as its mobile
                    applications, competitions; <br />
                    <br />• Use a Service for tasks that it is not intended for;{" "}
                    <br />
                    <br />
                    • Override any security feature of the Services; <br />
                    <br />
                    • Interfere with the operation of, or place an unreasonable
                    load on, the Services (e.g., spam, denial of service attack,
                    viruses, gaming algorithms); and/or <br />
                    <br />
                    • Violate the Community Guidelines or any additional terms
                    concerning a specific Service that are provided when you
                    sign up for or start using such Service. <br />
                    <br />
                  </span>
                </div>
              ) : active == 2 ? (
                <div className="tab_item tab_item_2">
                  <h3>CopyRight Policy</h3>
                  <p>
                    At GamesBox , we expect our users to respect the
                    intellectual property rights of others. All capitalized
                    terms used in the gamesboxCopyright Policy have the same
                    meaning as defined in the gamesboxTerms of Service, which
                    incorporates this policy by reference into its terms.
                  </p>
                  <h4>1. Discouragement of copyright infringement</h4>
                  <p>
                    We ask users of gamesboxto take care to avoid infringing
                    copyrights of others. If you infringe copyrights of others,
                    Your Content may be removed or suspended in whole or part.
                    It is our policy, in appropriate circumstances and our
                    discretion, to disable and/or terminate accounts of users
                    who repeatedly infringe or are charged repeatedly with
                    infringing the copyrights or other intellectual property
                    rights of others.
                  </p>
                  <p>
                    gamesboxrespects the intellectual property rights of
                    others and desires to offer a place which contains no
                    content that violates those rights. Our Terms of Services
                    requires that information posted by Users be accurate,
                    lawful and not in violation of the rights of third parties.
                    To promote these objectives, gamesboxprovides a process
                    for submission of complaints concerning content posted by
                    our Users. Our policy and procedures are described and/or
                    referenced in the sections that follow.
                  </p>
                  <p>
                    Please note that whether or not we disable access to or
                    remove content, gamesboxmay make a good faith attempt to
                    forward the written notification, including the
                    complainant’s contact information, to the User who posted
                    the content and/or take other reasonable steps to notify the
                    Member that gamesboxhas received notice of an alleged
                    violation of intellectual property rights or other content
                    violation. It is also our policy, in appropriate
                    circumstances and in our discretion, to disable and/or
                    terminate the accounts of Users, or groups as the case may
                    be, who infringe or repeatedly infringe the rights of others
                    or otherwise post unlawful content.
                  </p>
                  <p>
                    Please note that any notice or counter-notice you submit
                    must be truthful and must be submitted under penalty of
                    perjury. A false notice or counter-notice may give rise to
                    personal liability. You may therefore want to seek the
                    advice of legal counsel before submitting a notice or a
                    counter-notice.
                  </p>
                  <h4>
                    2. Recourse for copyright owners: Reporting complaints with
                    a notice
                  </h4>
                  <p>
                    We also provide a mechanism for copyright owners, or those
                    authorized to act on behalf of an owner of a copyright, to
                    report infringing use of their content on GamesBox . In
                    accordance with the Copyright office Government of India, we
                    will respond expeditiously to claims of copyright
                    infringement that are reported to our Copyright Agent. We
                    provide you the requirements of a proper complaint below.
                    For your convenience, the entire text of the Copyright act
                    GOI can be found online at: http://copyright.gov.in/
                  </p>
                  <p>
                    In order to provide us a complaint notice that complies with
                    the Copyright policy of GOI’s requirements, you should:
                  </p>
                  <p>
                    {" "}
                    1. Provide your contact information, including your mailing
                    address, telephone number, and, if available, an email
                    address. <br /> <br />
                    2. Identify the copyrighted work that you claim has been
                    infringed. If multiple copyrighted works are covered in your
                    Notice, you may provide a representative list of the
                    copyrighted works that you claim have been infringed. <br />{" "}
                    <br />
                    3. Provide information reasonably sufficient for us to
                    locate each instance of allegedly infringing material that
                    you are requesting to be removed or disabled. (For example,
                    by providing each URL where the material may be found on
                    GamesBox .) <br /> <br />
                    4. Include the following statements in the body of the
                    Notice: <br />
                    I declare that I have a good faith belief that the disputed
                    use of the copyrighted material or reference link to such
                    material is not authorized by the copyright owner, its
                    agent, or the law (e.g., as a fair use). <br /> <br />
                    I declare that the information in this Notice is accurate
                    and, under penalty of perjury, that I am the owner or
                    authorized to act on behalf of the owner of an exclusive
                    copyright that is allegedly infringed. <br /> <br />
                    5. Include a physical or electronic signature (e.g. typing
                    your printed name) 6. Deliver this Notice, with all items
                    completed to the Designated Copyright Agent for GamesBox ,
                    at: Attn: Copyright Agent
                  </p>{" "}
                  <br /> <br />
                  <p>
                    connect@GamesBox .com <br />
                    <br />
                    Or <br />
                    <br />
                    Attn: Copyright Agent <br />
                    <br />
                    GamesBox . <br />
                    <br />
                    11, street no. 3 <br />
                    <br />
                    Siraspur, Delhi -110042
                  </p>
                  <h4>3. If there is a copyright complaint against you</h4>
                  <p>
                    If you receive a notice that a posting or portion of Your
                    Content has been removed or suspended due to a copyright
                    complaint, that means that we removed it at the request of a
                    content owner. If you believe the material was removed in
                    error, you have the option to file a counter-notification
                    (the proper format for such counter-notice is defined
                    below). When we receive a proper counter-notification, we
                    will forward it to the party who originally complained about
                    the alleged copyright violation. If we do not receive a
                    notice from the original complainant within 10 business
                    days, stating that the original complainant is seeking a
                    court order to prevent further infringement of the content
                    at issue, we will clear the complaint from your account’s
                    record, and, at our discretion, we may replace the content
                    that was removed.
                  </p>
                  <h4>4. How to file a counter notification</h4>
                  <p>
                    Please note that there are potentially seriously
                    consequences for fraudulent or bad faith submissions of such
                    notifications or counter notifications. Such consequences
                    may be imposed pursuant to the Copyright office GOI. Before
                    submitting a counter-notification, you should be sure that
                    you are the actual rights holder of the content removed or
                    that you have a good faith belief that content was
                    erroneously removed. It is important to make sure that you
                    understand the repercussions of submitting a false counter
                    notification.
                  </p>
                  <p>
                    A proper counter notification should be sent by replying to
                    the email notice you received, or by sending it to
                    GamesBox ’s Copyright Agent as provided above, and it
                    should contain the following:
                  </p>
                  <p>
                    {" "}
                    1. Your name, address, and telephone number. <br /> <br />
                    2. The URLs of the content that was removed or disabled by
                    us (you can copy and paste the link provided in the
                    notification email) <br /> <br />
                    3. The following statements: <br /> <br />
                  </p>
                  <p>
                    I consent to the jurisdiction of NCT District Court for the
                    judicial district in which my address is located, or if my
                    address is outside India, for any judicial district in which
                    gamesboxis located. I will accept service of process from
                    the claimant of copyright of infringement.
                  </p>
                  \
                  <p>
                    I declare, under penalty of perjury, that I have a good
                    faith belief that the content was removed as a result of a
                    mistake or misidentification of the material to be removed
                    or disabled.
                  </p>
                  <p>
                    4. Your physical or electronic signature (e.g., typing your
                    full name)
                  </p>
                </div>
              ) : active == 3 ? (
                <div className="tab_item tab_item_3">
                  <h3>Community Policies</h3>
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      gamesboxis a company that aims to put its users first
                      when it comes to making important decisions.
                    </span>
                     It’s a place where creative people showcase their work,
                    share ideas with the rest of the community. To ensure this
                    initiative help our users to be more productive, propagate
                    ideas among the community, and plants the seed of innovation
                    among each other, gamesboxhas put in place a Community
                    Guidelines.
                  </p>
                  <span>Table of Contents:</span>
                  <h4>1. Rules for Making Great Community</h4>
                  <h5>1. Be Real</h5>
                  <p>
                    Unlike some other online services, our users need to be real
                    people, who provide their real names and accurate
                    information about themselves. It is not okay to provide
                    misleading information about yourself, your qualifications
                    or your work experience, affiliations or achievements on
                    GamesBox .
                  </p>
                  <p>
                    gamesboxis a place of creative people to interact
                    genuinely with one another. To maintain the quality of these
                    interactions, we remove accounts that engage in behavior
                    that undermines the authenticity of our community, including
                    accounts that use fake profiles (to artificially increase
                    appreciations or distribute spam), impersonate other people
                    or entities. For similar reasons, we also suspend any
                    accounts that accept (or offer) compensation in exchange for
                    positive comments or appreciations.
                  </p>
                  <h5>2. Be Respectful</h5>
                  <p>
                    We ask our members to behave professionally by not being
                    dishonest or inappropriate. We acknowledge the value of
                    discussions on the platform and around someone’s work, but
                    we do not want you to use gamesboxto shock or intimidate
                    others. It is not okay to share graphic images to shock
                    others, and it is not okay to share obscene images or
                    pornography on GamesBox .
                  </p>
                  <h5>3. Upload Your Own Work</h5>
                  <p>
                    gamesboxis a place for sharing your own creative work —
                    not for uploading or sharing other people’s work that you
                    like. Please do not upload projects related to non-creative
                    services or products that you are selling. If you want to
                    show your appreciation for someone else’s work, you can
                    scroll to the bottom of the project and click the “Like”
                    button or you can promote their work via social sharing
                    sites like Twitter, Facebook or Pinterest. But please don’t
                    upload other people’s work into your own portfolio. This
                    creates confusion about who originally authored the work
                    (and we are all about people getting credit for what they
                    create!)
                  </p>
                  <h5>4. Constructive Criticism</h5>
                  <p>
                    When you discuss and idea or a project, it’s okay to
                    disagree or not like someone’s work. But you need to be
                    professional and provide constructive criticism to the
                    project rather than bullying harassing or by using
                    inappropriate language.
                  </p>
                  <h5>5. Stay On-topic</h5>
                  <p>
                    Discussions must be about the creative work in the project
                    that you are commenting on. Don’t use comments on someone
                    else’s project to promote your own work or to link to other
                    websites. We consider off-topic posts ‘spam’ and we
                    deactivate accounts that repeatedly violate this guideline.
                  </p>
                  <h5>6. Be Nice</h5>
                  <p>
                    gamesboxshouldn’t be used to harm others. Terrorists and
                    those who engage in violent crimes are not welcome on
                    GamesBox . It is not okay to use GamesBox ‘s services to
                    harass, abuse, or send other unwelcomed communications to
                    people (e.g., junk mail, spam, chain letters, phishing
                    schemes). Do not use GamesBox ’s services to promote or
                    threaten violence or property damage, or for hate speech
                    acts like attacking people because of their race, ethnicity,
                    national origin, gender, sexual orientation, political or
                    religious affiliations, or medical or physical condition.
                    Also, don’t use GamesBox ’s services to intentionally
                    infect others with viruses, worms, or other software that
                    can destroy or interrupt their data or computer devices. It
                    is not okay to interfere or disrupt GamesBox ’s service.
                  </p>
                  <h5>
                    7. Put Creative Work not Suitable for Adults behind the
                    Filter
                  </h5>
                  <p>
                    gamesboxis all about helping creative people get exposure
                    for their work, and our “Adult filter” is in this spirit as
                    well. Many countries, schools and companies block web sites
                    that don’t offer safe browsing so, by offering and enforcing
                    this feature, we keep your work available to as broad of an
                    audience as possible.
                  </p>{" "}
                  <br />
                  <span>
                    • Your creative work is “Safe” if: It is appropriate for an
                    audience of all ages and geographies, and you wouldn’t get
                    in trouble for looking at it at work. Anything safe can be
                    seen by everyone everywhere, whether they’re registered with
                    gamesboxor not. <br /> <br />
                    • Your creative work should go behind the adult filter If:
                    It contains nudity, expletives, violence or any other
                    material making it unsuitable for a younger audience, a
                    global audience or people at work. Content behind the adult
                    filter is available only to registered gamesboxusers who
                    are over 18 and who live in countries where adult content is
                    legal. Also, please don’t put adult content in your profile
                    picture. <br /> <br />
                    • Share your creative work privately If: It depicts sexual
                    acts, more extreme or graphic nudity, highly offensive
                    content or graphically violent content. Although this type
                    of content is legal in many countries, we don’t allow it in
                    projects shared publicly on gamesbox— even behind the
                    adult filter. You can store it privately. If you want to
                    share it privately, make sure you’re sharing it only with
                    willing recipients who are over 18 and who live in a country
                    where the content you’re sharing is legal. <br /> <br />
                    • Don’t upload It at all if: your content depicts minors in
                    a sexual manner, or is otherwise legally obscene or illegal.
                    We also don’t allow content that clearly is intended to
                    promote hatred. <br /> <br />
                  </span>
                  <h5>
                    {" "}
                    • ote hatred. 8. Respect Others’ Rights and Follow the Law
                  </h5>
                  <p>
                    We do not want gamesboxto be used for illegal activities
                    or to violate the rights of others. Don’t use GamesBox ’s
                    services to commit fraud. Before sharing or using someone
                    else’s copyrighted works, Trademarks, private information,
                    or trade secrets, please make sure you have the legal right
                    to do so.
                  </p>
                  <p>
                    Don’t present other people’s work as your own or overstate
                    your own role in creating something. Don’t use other
                    people’s Trademarks without permission. If you aren’t sure
                    whether your use of someone else’s content or Trademark in
                    your own work is legal, you can consult publicly available
                    reference materials of copyrighting, payment and Trademarks,
                    the Chilling Effects website, or talk to an intellectual
                    property attorney. If you want to report misuse of your own
                    creative work or your own Trademark by one of our users, you
                    can do that here.
                  </p>
                  <h5>9. Respect Privacy:</h5>
                  <p>
                    Don’t use gamesboxto reveal private information about
                    other people. And don’t upload photographic portraits or
                    nude images of identifiable people unless you have their
                    permission.
                  </p>
                  <h5>11. Respect GamesBox ’s Rights</h5>
                  <p>
                    Please don’t violate the rights of GamesBox . You can find
                    more information about what this means in our Terms of
                    Services, but put simply, please don’t use GamesBox ’s
                    services to wrongfully take data or information. It is not
                    okay to suggest that you are affiliated with or endorsed by
                    gamesboxwhen you are not, and it is not okay to violate
                    GamesBox ’s intellectual property rights.
                  </p>
                </div>
              ) : active == 4 ? (
                <div className="tab_item tab_item_4">
                  <h3>Acceptable use policy</h3>
                  <p>Effective from 20 May 2021</p>

                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      gamesboxenables creative people to share their work,
                      get inspired, and create new knowledge to make us more
                      creative and innovative.
                    </span>
                     For this everyone on gamesboxwill need to feel safe
                    about their intellectual properties, designs, and other
                    creative contents.
                  </p>

                  <p>Table of Contents:</p>
                  <span>Rules for Use of GamesBox </span>

                  <h5>1. Rules for Use of GamesBox </h5>
                  <h4>1. Rules for Use of GamesBox </h4>
                  <h5>1.1 Engage with Others in a Constructive Way</h5>

                  <p>
                    Assume everyone on gamesboxis here to make it a great
                    resource with diverse backgrounds, beliefs, and opinions. It
                    is OK to disagree, but please be civil, respectful, and
                    considerate to other posters.
                  </p>

                  <h5>1.2 No Hate Speech</h5>
                  <p>
                    gamesboxis a place for civil discourse and does not
                    tolerate content that attacks or disparages an individual or
                    group based on race, gender, religion, nationality,
                    ethnicity, political group, sexual orientation or another
                    similar characteristic. Any generalizations about these
                    topics should be phrased as neutrally as possible.
                  </p>

                  <h5>1.3 No Harassment and Bullying</h5>
                  <p>
                    Abusive behavior directed towards private individuals is not
                    allowed. Repeated and unwanted contact constitutes a form of
                    harassment. Content should not make threats against others
                    or advocate violence, including self-harm.
                  </p>

                  <h5>1.4 Identity and Deceptive Activity</h5>
                  <p>
                    Your gamesboxprofile should use your real name or if you
                    are using representing a verified business it should be
                    legally incorporated business name and use accurate and
                    truthful credentials. Do not use gamesboxto impersonate
                    another person, act as another entity without authorization,
                    or create multiple accounts.
                  </p>

                  <h5>1.5 Intellectual Property and Personal Rights</h5>
                  <p>
                    Do not post content that infringes any intellectual property
                    or personal right of another party. Images, videos, projects
                    or writing taken from another source should be properly
                    attributed referenced and block quoted. Due credentials
                    should be given to all the content posted on GamesBox .
                  </p>

                  <h5>1.6 No Spam</h5>
                  <p>Do not use gamesboxto post or attract spam.</p>

                  <h5>1.7 No Malicious or Illegal Activity</h5>
                  <p>
                    Do not contribute viruses, malware, and other malicious
                    programs or engage in activity that harms or interferes with
                    the operation of gamesboxor seeks to circumvent security
                    or authentication measures. Do not use gamesboxto engage
                    in unlawful activity or to promote illegal acts.
                  </p>

                  <h5>1.8 Abide by Other gamesboxPolicies</h5>
                  <p>
                    Follow GamesBox ’s other policies, which gamesboxmay add
                    or change from time to time.
                  </p>
                  <p>
                    If you see something on gamesboxthat you believe violates
                    our guidelines or policies, please report it to us using the
                    in-product reporting tool, or via our contact form.
                  </p>
                  <p>
                    Violating the Acceptable Use Policy may result in the
                    removal of content, or in the limitation or termination of a
                    user’s access to GamesBox .
                  </p>
                </div>
              ) : active == 5 ? (
                <div className="tab_item tab_item_5">
                  <h3>Tradmark Olicy</h3>
                  <p>
                    At GamesBox , we expect our users to respect the
                    intellectual property rights of others. All capitalized
                    terms used in the gamesboxTrademark Policy have the same
                    meaning as defined in the gamesboxTerms of Service, which
                    incorporates this policy by reference into its terms.
                  </p>

                  <h4>
                    1. Discouragement of Trademark infringement of GamesBox 
                  </h4>
                  <p>
                    Trademarks should not be used in any way that causes
                    confusion about source of goods or sponsorship. If you
                    infringe Trademarks of others, Your Content may be removed
                    or suspended in whole or part. It is our policy, in
                    appropriate circumstances and our discretion, to disable
                    and/or terminate accounts of users who repeatedly infringe
                    or are charged repeatedly with infringing the Trademarks or
                    other intellectual property rights of others.
                  </p>

                  <h5>2. Recourse for Trademark Owners</h5>
                  <p>
                    If you are Trademark owner who believes your Trademark has
                    been infringed on GamesBox , we provide a mechanism for you
                    to notify us of your concerns. To help us respond
                    appropriately, we ask you to provide us the following
                    information:
                  </p>

                  <span>
                    1. Your full name and contact information, including
                    address, phone number, and email contact <br />
                    <br />
                    2. Identification of the Trademark and evidence of
                    registration rights. <br />
                    <br />
                    3. Your relationship to the owner of the Trademark rights.{" "}
                    <br />
                    <br />
                    4. Reasonably identification for us (e.g., URL link) of the
                    location on gamesboxwhere you believe infringement has
                    occurred <br />
                    <br />
                    5. A short description of why you believe the use in
                    question infringes your Trademark rights <br />
                    <br />
                    6. Verification that all of the information in your report
                    of infringement is accurate; that you have a good faith
                    belief that the use of the intellectual property, described
                    in the manner you have complained about, is not authorized
                    by the rights owners, its agent, or the law; and that you
                    are the owner, or authorized to act on behalf of the owner,
                    of an exclusive intellectual property that allegedly is
                    infringed <br />
                    <br />
                  </span>
                </div>
              ) : (
                active == 6 ? (
                  <div className="tab_item tab_item_6">
                    <h3>Privacy Policy</h3>
                    <p>
                      We are committed to be transparent with you about the data
                      we collect about you and how it is used and shared. By
                      using our Services, you consent to our use of your data
                      under this Privacy Policy.
                    </p>
                    <h4>1.1 Registration</h4>
                    <p>
                      To create an account, you provide data including your name
                      and email address. We may collect additional data from
                      social logins. We collect information directly from
                      individuals, from third-parties and automatically through
                      the GamesBox .
                    </p>
                    <h4>1.2 Account and profile information</h4>
                    <p>
                      When you create your profile on GamesBox , we collect
                      your name, contact information, and other information you
                      provide, such your area of interest, your education, work
                      experience, skills, photo, city or area, etc. Your name,
                      photo, and any other information that you choose to add to
                      your public-facing profile will be available for viewing
                      to users on GamesBox . Once you activate a profile, other
                      users will be able to see in your profile certain
                      information about your activity on GamesBox , such as
                      project you published and the information you list as
                      credentials. For more information about your choices for
                      publicly displayed information, see the section below
                      about Your choices.
                    </p>
                    <h4>1.3 Your content</h4>
                    <p>
                      We collect and store the information and content that you
                      post on GamesBox , including your projects, ideas,
                      discussions, and comments. Your Content, date and time
                      stamps, and all associated comments are publicly viewable
                      on GamesBox , along with your name. This also may be
                      indexed by search engines and be republished elsewhere on
                      the Internet in accordance with our Terms of Service. For
                      more information about what you can change, see the below
                      section on Your Choices.
                    </p>
                    <h4>1.4 Communications</h4>
                    <p>
                      When you communicate with us (via email, phone, through
                      GamesBox , subscription on our website or otherwise), we
                      may maintain a record of your communication and start a
                      conversation if required.
                    </p>
                    <h4>1.5 Integrated service provider and linked networks</h4>
                    <p>
                      If you elect to connect your gamesboxaccount to another
                      online service provider, such as a social networking
                      service (“Integrated Service Provider”), you will be
                      allowing us to pass to and receive from the Integrated
                      Service Provider your log-in information and other user
                      data. You may elect to sign in or sign up on GamesBox 
                      through a linked network like Facebook or Google (each a
                      “Linked Network”). If you elect to sign up through a
                      Linked Network, you will be asked to enable sharing of
                      your contacts with GamesBox . If you agree to this
                      sharing, in order to facilitate a more connected
                      experience, we may send notices informing your contacts
                      you have joined gamesboxso that you and your contacts
                      can find and follow each other on GamesBox . The specific
                      information we may collect varies by Integrated Service
                      Provider, but the permissions page for each will describe
                      the relevant information. Integrated Service Providers
                      control how they use and share your information; you
                      should consult their respective Privacy policies for
                      information about their practices.
                    </p>
                    <h4>
                      1.6 Automatically collected information about your
                      activity
                    </h4>
                    <p>
                      We use cookies, log files, pixel tags, local storage
                      objects, and other tracking technologies to automatically
                      collect information about your activities, such as your
                      searches, page views, date and time of your visit, and
                      other information about your use of GamesBox . We also
                      collect and may store information that your computer or
                      mobile device provides to us in connection with your use
                      of gamesboxsuch as your browser type, type of computer
                      or mobile device, browser language, IP address, mobile
                      carrier, unique device identifier, location, and requested
                      and referring URLs. We also receive information when you
                      view content on or otherwise interact with GamesBox ,
                      even if you have not created an account. For more
                      information, see the Cookies, Pixels and Tracking section
                      below.
                    </p>
                    <h4>1.7 Third Parties</h4>
                    <p>
                      We may receive information about you from third parties,
                      such as other users, partners (like competition organizing
                      agency), or our affiliated companies. For example, other
                      users may share or disclose information about you, such as
                      when they mention you in their content, reply to Your
                      Content, or share information about Your Content via other
                      sites or services. We may also collect additional
                      information about our “Users” and “Visitors” from third
                      party apps (like google analytics, facebook pixel or
                      through the Ad Services we run with third parties).
                    </p>
                    <h4>1.8 Shared Information</h4>
                    <p>
                      We may collect information about you from your shared
                      contacts who may invite you using our invitation service.
                      An employer/team mate may also provide information about
                      their employees or contractors who make use of these
                      Services. For example, we will get contact information for
                      “Organization Page” administrators and for authorizing
                      users of our premium Services, such as our recruiting,
                      sales or learning products.
                    </p>
                    <h4>1.9 Other</h4>
                    <p>
                      Our Services are dynamic and we often introduce new
                      features, which may require the collection of new
                      information. If we collect materially different personal
                      data or materially change how we use your data, we will
                      notify you and may also modify this Privacy Policy.
                    </p>
                    <h2>2. How we use your information</h2>
                    <p>
                      How we use your personal data will depend on which
                      Services you use, how you use those Services and the
                      choices you make in your Settings. We use the data that we
                      have about you to provide, support, personalize and make
                      our Services more relevant and useful to you and others.
                      We do not sell your personally identifying information –
                      such as your name and contact information – to third
                      parties to use for their own marketing purposes.
                    </p>
                    <p>gamesboxuses the information we collect:</p>
                    <h4>2.1 Network</h4>
                    <p>
                      We will use data about you (such as profiles you have
                      viewed or data provided through address book uploads or
                      partner integration) to suggest connections for you and
                      others (e.g. Members who share your contacts) and enable
                      you to invite others to become a Member and connect with
                      you. You can also opt to allow us to use your precise
                      location or proximity to suggest other users in your
                      region to connect with you. We use your location accuracy
                      to tailor the content and information that we may send or
                      display to you on GamesBox , to offer location to
                      customization, and personalized help and instructions, and
                      to otherwise personalize your experiences while using
                      GamesBox .
                    </p>
                    <p>
                      It is your choice whether to invite someone to connect
                      with you, your invitation will include your name, photo,
                      network and contact information. We will send invitation
                      reminders to the person you invited. You can choose
                      whether or not to share your own list of connections with
                      your connections.
                    </p>
                    <h4>2.2 Information</h4>
                    <p>
                      Our Services allow you to stay informed about news event
                      and ongoing ideas from GamesBox . Our Services also allow
                      you to recommend relevant content across our Services,
                      suggest skills you may have to add to your profile and
                      skills that you might need to pursue your next
                      opportunity.
                    </p>
                    <p>
                      For marketing and promotional purposes, such as to send
                      you news and newsletters, special offers, and promotions,
                      or to otherwise contact you about products or information
                      we think may interest you, including information about
                      third party products and services.
                    </p>
                    <p>
                      We use your content, activity and other data, including
                      your name and picture, to provide notices to your network
                      and others. For example, subject to your Settings we may
                      notify others that you have updated your profile, uploaded
                      a project, took a social action (like, appreciate,
                      discussed, followed, shared) made followed someone.
                    </p>
                    <h4>2.3 Communications</h4>
                    <p>
                      We will contact you through email, notices posted on our
                      websites or apps, messages to your Inbox, and other ways
                      through our Services, including text messages and push
                      notifications. We will send you messages about the
                      availability of our Services, security or other
                      service-related issues. We also send messages about how to
                      use the Services, network updates, reminders, job
                      suggestions, upcoming competitions and promotional
                      messages from us and our partners. You may change your
                      communication preferences at any time. Please be aware
                      that you cannot opt out of receiving service message from
                      us, including security and legal notices.
                    </p>
                    <p>
                      We may also use information to track the success of our
                      communications and marketing, and to gather metrics we
                      provide to third parties or our business partners.
                    </p>
                    \<h4>2.4 Marketing</h4>
                    <p>
                      We use data and content about our users to track the
                      success of our communications and marketing, and to gather
                      metrics we provide to third parties or our business
                      partners.
                    </p>
                    <h4>2.5 Developing Services and Research</h4>
                    <span>
                      • Service Development: We use data including public
                      feedback, to conduct research and development for the
                      further development of our Services in order to provide
                      you and others with a better, more intuitive and
                      personalized experience, drive User growth and engagement
                      on our Services, and help to make gamesboxbetter and
                      make Design process more open. <br />
                      • Other Research: We use the data available to us to
                      research upon the latest trends in various fields of
                      design, job opportunities, skillsets needed for these jobs
                      and policies that help bridge the gap in various
                      industries and geographical areas. In some case we work
                      with trusted third parties to perform this research, under
                      controls that are designed to protect your Privacy. We
                      publish or allow others to publish these aggregated data
                      rather than anyone’s personal data. <br />
                      • Surveys: Polls and surveys are conducted by us and
                      others through out Services. You are not obligated to
                      respond to polls or surveys and you have choices about the
                      information you provide. <br />
                    </span>
                    <h4>2.6 Customer Support</h4>
                    <p>
                      We use data including your communications to provide you
                      the services we offer on GamesBox , communicate with you
                      about your use of GamesBox , respond to your inquiries,
                      provide troubleshooting, and for other customer service
                      purposes.
                    </p>
                    <h4>2.7 Security and Investigations</h4>
                    <p>
                      We use your data including communication to comply with
                      legal obligations, as part of our general business
                      operations, and for other business administration purposes
                      if we think it’s necessary to investigate possible fraud
                      or other violation of our Terms of Service or this Privacy
                      Policy and/or attempts to harm fellow Users.
                    </p>
                    <h2>3. How we share your information</h2>
                    <p>
                      We share information as set forth below, and where
                      individuals have otherwise consented:
                    </p>
                    <h4>3.1 Profile</h4>
                    <p>
                      Your profile is fully visible to all Users of our
                      Services. As detailed in our Help, your Settings you may
                      control how an individual or public sees your information.
                      You have Privacy Settings for each of the information that
                      is being displayed to the public on GamesBox . Your name
                      and profile picture will be visible to all the
                      participants by default.
                    </p>
                    <h4>3.2 Your content</h4>
                    <p>
                      Through our services users on gamesboxcan see your
                      content if they are following tags associated with your
                      content, they are following you or your project. We may
                      also recommend popular projects may include yours on
                      gamesboxand show it to others. We also show aggregated
                      activities of yours on gamesboxto the people who are
                      following you.
                    </p>
                    <h4>3.3 Service providers</h4>
                    <p>
                      We may share your information with third party service
                      providers who use this information to perform services for
                      us, such as payment processors, hosting providers,
                      auditors, advisors, consultants, customer service and
                      support providers, as well as those who assist us in
                      providing gamesboxassociated services.
                    </p>
                    <h4>3.4 Affiliates</h4>
                    <p>
                      The information collected about you may be accessed by or
                      shared with subsidiaries and affiliates of GamesBox ,
                      whose use and disclosure of your personal information is
                      subject to this Privacy Policy.
                    </p>
                    <h4>3.5 Business Transfers</h4>
                    <p>
                      We may disclose or transfer information, including
                      personal data, as part of any merger, sale, and transfer
                      of our assets, acquisition or restructuring of all or part
                      of our business, bankruptcy, or similar event.
                    </p>
                    <h4>3.6 Legally Required</h4>
                    <p>
                      We may disclose your information if we are required to do
                      so by law.
                    </p>
                    <h4>3.7 Protection of Rights</h4>
                    <p>
                      We may disclose information where we believe it necessary
                      to respond to claims asserted against us or, comply with
                      legal process (e.g., subpoenas or warrants), enforce or
                      administer our Agreements and Terms, for fraud prevention,
                      risk assessment, investigation, and protect the rights,
                      property or safety of GamesBox , its users, or others.
                    </p>
                    <h4>3.8 Your content and activity</h4>
                    <p>
                      Your Content, including your name, profile picture,
                      profile information, and certain associated activity
                      information is available to other users of gamesboxand
                      may be viewed publicly. Public viewing includes
                      availability to non-registered visitors and can occur when
                      users share Your Content across other sites or services.
                      In addition, Your Content may be indexed by search engines
                      if not opted out. See the section below about Your Choices
                      for information about how you may change how certain
                      information is shared or viewed by others.
                    </p>
                    <h4>3.9 Anonymized and Aggregated Data</h4>
                    <p>
                      We may share aggregate or de-identified information with
                      third parties for research Cookie Policy, marketing,
                      analytics and other purposes, provided such information
                      does not identify a particular individual.
                    </p>
                    <h4>3.10 Cookies, pixels and tracking</h4>
                    <p>
                      We and our third-party providers use cookies, clear
                      GIFs/pixel tags, JavaScript, local storage, log files, and
                      other mechanisms to automatically collect and record
                      information about your usage and browsing activities on
                      the gamesboxand across third party sites or online
                      services. We may combine this information with other
                      information we collect about users. Below, we provide a
                      brief summary these activities. For more detailed
                      information about these mechanisms and how we collect
                      activity information, see our Cookie policy.
                    </p>
                    <span>
                      {" "}
                      • Cookies: These are small files with a unique identifier
                      that are transferred to your browser through our websites.
                      They allow us to remember users who are logged in, to
                      understand how users navigate through and use GamesBox ,
                      and to display personalized content and targeted ads
                      (including on third party sites and applications). <br />{" "}
                      <br />
                      • Pixels, web beacons, clear GIFs: These are tiny graphics
                      with a unique identifier, similar in function to cookies,
                      that we use to track the online movements of users of
                      gamesboxand the web pages of users of our associated
                      organizations, and to personalize content. We also use
                      these in our emails to let us know when they have been
                      opened or forwarded, so we can gauge the effectiveness of
                      our communications. <br /> <br />• Analytics Tools: We may
                      use internal and third party analytics tools, including
                      Google Analytics. The third party analytics companies we
                      work with may combine the information collected with other
                      information they have independently collected from other
                      websites and/or other online products and services. Their
                      collection and use of information is subject to their own
                      Privacy policies.
                    </span>
                    <h4>3.11 How we protect your information</h4>
                    <p>
                      The security of your information is important to us.
                      gamesboxhas implemented safeguards to protect the
                      information we collect. However, no website or Internet
                      transmission is completely secure. We urge you to take
                      steps to keep your personal data safe, such as choosing
                      strong password and keeping it private, as well as logging
                      out of your user account, and closing your web browser
                      when finished using GamesBox .
                    </p>
                    <h4>3.12 Access and amend your information</h4>
                    <p>
                      You may update or correct your account information at any
                      time by logging in to your account. You may also make a
                      number of other adjustments to Settings or the display of
                      information about you as described in more detail in the
                      following section about Your Choices.
                    </p>
                    <h2>4. Your choices and obligations</h2>
                    <p>
                      You may, of course, decline to submit information through
                      GamesBox , in which case we may not be able to provide
                      certain services to you. You may also control the types of
                      notifications and communications we send, limit the
                      information shared within gamesboxabout you, and
                      otherwise amend certain Privacy Settings. Here is some
                      further information about some of your choices:
                    </p>
                    <h4>4.1 Your Content</h4>
                    <p>
                      You may edit or delete the projects that you published at
                      any time. Any deleted content will be removed from third
                      party sites from which it has been shared via GamesBox ’s
                      sharing features; however, we have no control over
                      deletions or changes to Your Content if it has been shared
                      manually.
                    </p>
                    <h4>4.2 Adult Content</h4>
                    <p>
                      In your profile’s Privacy Settings, you can elect whether
                      to receive adult content only if you are 18 years old.
                    </p>
                    <h4>4.3 Emails and Communications</h4>
                    <p>
                      When you join gamesboxby signing up for an account or
                      creating a profile, as part of the service, you will
                      receive the gamesboxcontaining content that we believe
                      may match your interests. As permitted by law, you are
                      also opting to receive other emails from us that may be of
                      interest to you as a user of the GamesBox . You can
                      manage your email and notice preferences in your account
                      profile Settings, under your Emails and Notifications
                      Settings, but note that you cannot opt out of receiving
                      certain administrative or legal notices from us. If you
                      opt-out of receiving emails about recommendations or other
                      information we think may interest you, we may still send
                      you transactional e-mails about your account or any
                      services you have requested or received from us. It may
                      take up to 30 business days for us to process opt-out
                      requests.
                    </p>
                    <p>
                      Additionally, by joining GamesBox , you are agreeing to
                      receive feedback and communications from third-parties
                      within GamesBox , including third parties who may comment
                      on your postings within GamesBox 
                    </p>
                    <h4>4.4 Topics</h4>
                    <p>
                      You can change topics that you follow or that your profile
                      lists as areas that you know about.
                    </p>
                    <h4>4.5 Credentials</h4>
                    <p>
                      You can change your credentials that are displayed in your
                      profile or in connection with a specific answer.
                    </p>
                    <h4>4.7 Deleted or deactivated account</h4>
                    <p>
                      If you choose Delete Your Account in your Privacy
                      Settings, then all of Your Content except your ideas will
                      be removed from public visibility on GamesBox , and it
                      may not be restored by us, even if you change your mind.
                      If you choose to Deactivate Your Account, then you will no
                      longer receive any communications from us; however, Your
                      Content will remain on the gamesbox. Once you
                      deactivate your account, you can reactivate it any time by
                      choosing to log in.
                    </p>
                    <h4>4.9 Linked networks</h4>
                    <p>
                      You may connect or disconnect your Linked Networks, such
                      as Facebook, through your profile page. Once you
                      disconnect it, we will no longer receive information from
                      that Linked Network going forward; we may still maintain
                      the information we have already collected.
                    </p>
                    <h2>5. Other important information</h2>
                    <h4>5.1 Children’s Privacy</h4>
                    <p>
                      We do not knowingly collect or solicit personal
                      information from anyone under the age of 13 or knowingly
                      allow such persons to register. If we become aware that we
                      have collected personal information from a child under the
                      relevant age without parental consent, we take steps to
                      delete that information.
                    </p>
                    <h4>5.2 Do-Not-Track Signals</h4>
                    <p>
                      Please note we do not change system behavior within
                      gamesboxin response to browser requests not to be
                      tracked. You may, however, disable certain tracking by
                      third-parties.
                    </p>
                    <p>5.3 Links to Other Web Sites</p>
                    <p>
                      gamesboxmay contain links to third party sites or
                      online services. We are not responsible for the practices
                      of such third parties, whose information practices are
                      subject to their own policies and procedures, not to this
                      Privacy Policy.
                    </p>
                    <h4>5.4 Contact Us</h4>
                    <p>
                      If you have any questions about our practices or this
                      Privacy Policy, please contact us at
                      connect@GamesBox .com, or send mail to:
                    </p>
                    <p>
                            gamesbox<br /> <br />
                            Attn: Privacy <br /> <br />
                            11, Street no.3, <br /> <br />
                      Siraspur, Delhi – 110042
                    </p>
                    <h4>5.5 Changes to Our Privacy Policy</h4>
                    <p>
                      If we change our Privacy Policies and procedures, we will
                      post those changes on this page. If we make any changes to
                      this Privacy Policy that materially change how we treat
                      your personal information, we will endeavor to provide you
                      with reasonable notice of such changes, such as via
                      notification on gamesboxor to your email address of
                      record, and where required by law, we will obtain your
                      consent or give you the opportunity to opt out of such
                      changes.
                    </p>
                  </div>
                ) : ""
              )}
            </div>

            <div className="tab_foot flex_align_justify">
              <button className="decline">Decline</button>
              <button className="agree">Agree</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
